import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import styles from "./GoTogetherComponent.module.scss";

const GoTogetherComponent = () => {
    return (
        <>
            <div className="hero">
                <section className="landing-section" style={{ display: "flex", flexDirection: "row-reverse" }}>
                    <div className="hero__left">
                        <div style={{ marginTop: "2rem" }} className="get-started-box">
                            <a className="get-started-box__item" target={"_blank"} rel="noreferrer" href="https://play.google.com/store/apps/details?id=bd.com.transporter">
                                <span>অ্যাপ ডাউনলোড করুন</span>
                                <img src="https://pathao.com/bn/wp-content/uploads/sites/6/2019/02/Google-Play-nb.png" alt="" />
                            </a>
                            <Link to="/rider-registration" className="get-started-box__item">
                                <span>রাইডার হিসেবে আয় করুন</span>
                                <button>রেজিস্ট্রেশন করুন</button>
                            </Link>
                        </div>
                    </div>
                    <div className="hero__left" style={{ justifyContent: "flex-start" }}>
                        <span className="hero__left__medium-text"> ট্রান্সপোর্টার </span>
                        <span className="hero__left__large-text">হাতের মুঠোয় অনডিমান্ড সার্ভিস</span>
                        <span className="hero__left__small-text">ইমারজেন্সি অনডিমান্ড সার্ভিস লাগবে? ট্রান্সপোর্টার আছে আপনার সমস্যার সমাধানে!</span>
                    </div>
                </section>
            </div>

            <section className={styles.earn_more + " container"}>
                <h2 className={styles.earn_more_head}>earn.MORE</h2>
                <h3 className={styles.earn_more_subhead}>Transporter offers a number of ways to earn more. Join us. Let us do it for you.</h3>
                <Row gutter={[25, 25]}>
                    <Col lg={6} md={12} sm={12} xs={24}>
                        <div className={styles.earn_more_card}>
                            <img className={styles.earn_more_card_img} src="https://via.placeholder.com/250/09f/fff.webp" alt="car" />
                            <div className={styles.earn_more_card_title}>
                                Car/SUV/Microbus <br /> Owner
                            </div>
                            <Link to="/rider-registration" className={styles.earn_more_card_btn}>
                                Join Now
                            </Link>
                        </div>
                    </Col>

                    <Col lg={6} md={12} sm={12} xs={24}>
                        <div className={styles.earn_more_card}>
                            <img className={styles.earn_more_card_img} src="https://via.placeholder.com/250/09f/fff.webp" alt="car" />
                            <div className={styles.earn_more_card_title}>
                                Motorcycle <br /> Driver
                            </div>
                            <Link to="/rider-registration" className={styles.earn_more_card_btn}>
                                Join Now
                            </Link>
                        </div>
                    </Col>

                    <Col lg={6} md={12} sm={12} xs={24}>
                        <div className={styles.earn_more_card}>
                            <img className={styles.earn_more_card_img} src="https://via.placeholder.com/250/09f/fff.webp" alt="car" />
                            <div className={styles.earn_more_card_title}>
                                Delivery <br /> Facilitator
                            </div>
                            <Link to="/rider-registration" className={styles.earn_more_card_btn}>
                                Join Now
                            </Link>
                        </div>
                    </Col>

                    <Col lg={6} md={12} sm={12} xs={24}>
                        <div className={styles.earn_more_card}>
                            <img className={styles.earn_more_card_img} src="https://via.placeholder.com/250/09f/fff.webp" alt="car" />
                            <div className={styles.earn_more_card_title}>
                                3 Wheeler <br /> Owner
                            </div>
                            <Link to="/rider-registration" className={styles.earn_more_card_btn}>
                                Join Now
                            </Link>
                        </div>
                    </Col>

                    <Col lg={6} md={12} sm={12} xs={24}>
                        <div className={styles.earn_more_card}>
                            <img className={styles.earn_more_card_img} src="https://via.placeholder.com/250/09f/fff.webp" alt="car" />
                            <div className={styles.earn_more_card_title}>
                                Vehicle & Delivery
                                <br /> Agent
                            </div>
                            <Link to="/rider-registration" className={styles.earn_more_card_btn}>
                                Join Now
                            </Link>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <div className={styles.earn_more_cta}>
                            <div className={styles.earn_more_cta_left}>
                                <div>Don't have a car/microbus/motorcycle? </div>
                                <div>Do you know how to drive a car or ride a motorcycle?</div>
                                <div>Do you have a valid professional driving license?</div>
                                <div>We can find you a job!</div>
                            </div>
                            <div className={styles.earn_more_cta_right}>
                                <Link to="/rider-registration" className={styles.earn_more_card_btn}>
                                    Apply Now
                                </Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </section>

            <section className={`container ${styles.made_in}`}>
                <div className={styles.made_in_text}>Made in</div>
                <img className={styles.made_in_flag} src="/Flag_of_Bangladesh.svg" alt="flag" />
            </section>
        </>
    );
};

export default GoTogetherComponent;
