//importing components
import Layout from "../Components/Layout";
import {
    Row,
    Col,
    Form,
    Input,
    Button
} from 'antd';


//importing icons
import { BsChevronRight, BsBicycle } from 'react-icons/bs';
import { FaCarSide, FaMotorcycle, FaShuttleVan } from 'react-icons/fa';
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { GiJeep } from 'react-icons/gi';
import { MdOutlineElectricRickshaw } from 'react-icons/md';
import { useState } from "react";
import BikeRegistrationForm from "../Components/BikeRegistrationForm/BikeRegistrationForm";
import CarRegistrationForm from "../Components/CarRegistrationForm/CarRegistrationForm";
import BiCycleRegistrationForm from "../Components/BiCycleRegistrationForm/BiCycleRegistrationForm";
import RickshawRegistration from "../Components/RickshawRegistration/RickshawRegistration";

const RiderRegistration = () => {

    const { form } = Form;

    const [step, setStep] = useState(1);
    const [cellNumber, setCellNumber] = useState('');
    const [city, setCity] = useState('');
    const [vehicleType, setVehicleType] = useState('bike');

    return (
        <Layout>
            <section className="main-container register_heading" style={{ backgroundColor: '#DFF7F9', padding: '4rem 0', borderRadius: 3 }}>
                <span className="h-center" style={{ fontSize: '2.2rem', fontWeight: 'bold' }}>Join Us as Transporter</span>
                <h2 style={{ fontSize: '3.5rem', lineHeight: 1 }} className="title">
                    and Earn as You Like
                </h2>
            </section>


            <div style={{ width: '100%', backgroundColor: 'var(--color-grey-light-2)' }} className="h-center rider_reg_container">
                <section className="main-container">
                    <Row gutter={[46, 46]} style={{ margin: "1.5rem 0 1.5rem 0" }}>
                        <Col lg={24} xs={24} sm={24} style={{ display: 'flex', justifyContent: 'center', }}>
                            <div className="rider-reg" style={{ maxWidth: '500px', width: '100%' }}>
                                {step !== 1 && (
                                    <div className="back_btn" onClick={() => {
                                        setStep(step => step - 1)
                                    }}>
                                        <MdOutlineArrowBackIosNew size={25} style={{ cursor: "pointer" }} />
                                    </div>
                                )}

                                {step === 1 &&
                                    (
                                        <>
                                            <h4 style={{ fontSize: '2.6rem', fontWeight: 'bold', textAlign: "center", lineHeight: .5 }}>Register Now</h4>
                                            <span style={{ textAlign: 'center', display: 'block', fontSize: '1.6rem' }}>as Transporter and Start Earning</span>
                                            <Form
                                                form={form}
                                                layout="vertical"
                                                onFinish={(values) => {
                                                    setCellNumber(values.cellNumber);
                                                    setStep(2)
                                                }}
                                                style={{ marginTop: '2rem' }}
                                            >
                                                <Row>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            name="cellNumber"
                                                            label="Mobile Number"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'আপনার ১১ ডিজিট এর মোবাইল নম্বর দিন'
                                                                },
                                                                {
                                                                    pattern: /^[\d]{11,11}$/,
                                                                    message: "আপনার ১১ ডিজিট এর মোবাইল নম্বর দিন",
                                                                },]}
                                                        >
                                                            <Input
                                                                addonBefore={'+88'}
                                                                style={{
                                                                    width: '100%',
                                                                    marginBottom: '6rem'
                                                                }}
                                                                type="number"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item>
                                                            <Button style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }} block size={"large"} htmlType='submit' type="primary" className="primary-button">
                                                                Next
                                                                <img src="/arrow-white.png" style={{ width: '35px', position: 'absolute', right: 20 }} alt="" />
                                                            </Button>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                                    <a style={{textAlign: 'center'}} href="/registration-guide" target={'_blank'}>রেজিস্ট্রেশন গাইড</a>
                                                </div>
                                            </Form>
                                        </>
                                    )

                                }

                                {step === 2 && (
                                    <>
                                        <h4
                                            style={{ fontSize: '2.4rem', marginTop: '-.5rem', marginBottom: '.5rem', fontWeight: 'bold', textAlign: "center", lineHeight: 1 }}
                                        >
                                            Select Your Current City
                                        </h4>
                                        <span style={{ textAlign: 'center', alignSelf: 'center', display: 'flex', justifyContent: 'center', marginBottom: '3rem' }}>(You can drive anywhere in Bangladesh where our operation exists)</span>

                                        <Button
                                            block
                                            size={"large"}
                                            htmlType='submit'
                                            type="primary"
                                            className="primary-button"
                                            style={{ display: "flex", alignItems: "center", marginBottom: "2rem" }}
                                            onClick={() => {
                                                setCity("dhaka")
                                                setStep(3)
                                            }}
                                        >
                                            <span> Dhaka </span>
                                            <BsChevronRight size={18} style={{ marginLeft: "auto" }} />
                                        </Button>

                                        <Button
                                            block
                                            size={"large"}
                                            htmlType='submit'
                                            type="primary"
                                            className="primary-button"
                                            style={{ display: "flex", alignItems: "center", marginBottom: "2rem" }}
                                            onClick={() => {
                                                setCity("coxsBazar")
                                                setStep(3)
                                            }}
                                        >
                                            <span> Cox's Bazar </span>
                                            <BsChevronRight size={18} style={{ marginLeft: "auto" }} />
                                        </Button>

                                        <Button
                                            block
                                            size={"large"}
                                            htmlType='submit'
                                            type="primary"
                                            className="primary-button"
                                            style={{ display: "flex", alignItems: "center", marginBottom: "2rem" }}
                                            onClick={() => {
                                                setCity("nilphamari")
                                                setStep(3)
                                            }}
                                        >
                                            <span> Nilphamari </span>
                                            <BsChevronRight size={18} style={{ marginLeft: "auto" }} />
                                        </Button>

                                        <Button
                                            block
                                            size={"large"}
                                            htmlType='submit'
                                            type="primary"
                                            className="primary-button"
                                            style={{ display: "flex", alignItems: "center", marginBottom: "2rem" }}
                                            onClick={() => {
                                                setCity("dinajpur")
                                                setStep(3)
                                            }}
                                        >
                                            <span> Dinajpur </span>
                                            <BsChevronRight size={18} style={{ marginLeft: "auto" }} />
                                        </Button>

                                        <Button
                                            block
                                            size={"large"}
                                            htmlType='submit'
                                            type="primary"
                                            className="primary-button"
                                            style={{ display: "flex", alignItems: "center", marginBottom: "2rem" }}
                                            onClick={() => {
                                                setCity("thakurgaon")
                                                setStep(3)
                                            }}
                                        >
                                            <span> Thakurgaon </span>
                                            <BsChevronRight size={18} style={{ marginLeft: "auto" }} />
                                        </Button>

                                        <Button
                                            block
                                            size={"large"}
                                            htmlType='submit'
                                            type="primary"
                                            className="primary-button"
                                            style={{ display: "flex", alignItems: "center", marginBottom: "2rem" }}
                                            onClick={() => {
                                                setCity("panchagarh")
                                                setStep(3)
                                            }}
                                        >
                                            <span> Panchagarh </span>
                                            <BsChevronRight size={18} style={{ marginLeft: "auto" }} />
                                        </Button>

                                        <Button
                                            block
                                            size={"large"}
                                            htmlType='submit'
                                            type="primary"
                                            className="primary-button"
                                            style={{ display: "flex", alignItems: "center", marginBottom: "2rem" }}
                                            onClick={() => {
                                                setCity("rangpur")
                                                setStep(3)
                                            }}
                                        >
                                            <span> Rangpur </span>
                                            <BsChevronRight size={18} style={{ marginLeft: "auto" }} />
                                        </Button>

                                        <Button
                                            block
                                            size={"large"}
                                            htmlType='submit'
                                            type="primary"
                                            className="primary-button"
                                            style={{ display: "flex", alignItems: "center", marginBottom: "2rem" }}
                                            onClick={() => {
                                                setCity("kurigram")
                                                setStep(3)
                                            }}
                                        >
                                            <span> Kurigram </span>
                                            <BsChevronRight size={18} style={{ marginLeft: "auto" }} />
                                        </Button>

                                        <Button
                                            block
                                            size={"large"}
                                            htmlType='submit'
                                            type="primary"
                                            className="primary-button"
                                            style={{ display: "flex", alignItems: "center", marginBottom: "2rem" }}
                                            onClick={() => {
                                                setCity("lalmonirhat")
                                                setStep(3)
                                            }}
                                        >
                                            <span> Lalmonirhat </span>
                                            <BsChevronRight size={18} style={{ marginLeft: "auto" }} />
                                        </Button>

                                        <Button
                                            block
                                            size={"large"}
                                            htmlType='submit'
                                            type="primary"
                                            className="primary-button"
                                            style={{ display: "flex", alignItems: "center", marginBottom: "2rem" }}
                                            onClick={() => {
                                                setCity("others")
                                                setStep(3)
                                            }}
                                        >
                                            <span> Others </span>
                                            <BsChevronRight size={18} style={{ marginLeft: "auto" }} />
                                        </Button>


                                    </>
                                )}

                                {step === 3 && (
                                    <>
                                        <h4
                                            style={{ fontSize: '2.4rem', marginTop: '-.5rem', marginBottom: '3rem', fontWeight: 'bold', textAlign: "center", lineHeight: 1, }}
                                        >
                                            Select Vehicle Type
                                        </h4>

                                        <Button
                                            block
                                            size={"large"}
                                            htmlType='submit'
                                            type="primary"
                                            className="primary-button"
                                            style={{ display: "flex", alignItems: "center", marginBottom: "2rem" }}
                                            onClick={() => {
                                                setVehicleType("car")
                                                setStep(4)
                                            }}
                                        >
                                            <span style={{ display: "flex", alignItems: 'center', gap: "1.5rem" }}>
                                                <FaCarSide size={25} />
                                                <span>Car</span>
                                            </span>
                                            <BsChevronRight size={18} style={{ marginLeft: "auto" }} />
                                        </Button>

                                        <Button
                                            block
                                            size={"large"}
                                            htmlType='submit'
                                            type="primary"
                                            className="primary-button"
                                            style={{ display: "flex", alignItems: "center", marginBottom: "2rem" }}
                                            onClick={() => {
                                                setVehicleType("bike")
                                                setStep(4)
                                            }}
                                        >
                                            <span style={{ display: "flex", alignItems: 'center', gap: "1.5rem" }}>
                                                <FaMotorcycle size={25} />
                                                <span> Bike </span>
                                            </span>
                                            <BsChevronRight size={18} style={{ marginLeft: "auto" }} />
                                        </Button>

                                        <Button
                                            block
                                            size={"large"}
                                            htmlType='submit'
                                            type="primary"
                                            className="primary-button"
                                            style={{ display: "flex", alignItems: "center", marginBottom: "2rem" }}
                                            onClick={() => {
                                                setVehicleType("rickshaw")
                                                setStep(4)
                                            }}
                                        >
                                            <span style={{ display: "flex", alignItems: 'center', gap: "1.5rem" }}>
                                                {/* <img style={{ width: "2.5rem", height: "2.5rem" }} src="/rickshaw.svg" alt="" /> */}
                                                <MdOutlineElectricRickshaw size={25} />
                                                <span> Three Wheeler </span>
                                            </span>
                                            <BsChevronRight size={18} style={{ marginLeft: "auto" }} />
                                        </Button>

                                        <Button
                                            block
                                            size={"large"}
                                            htmlType='submit'
                                            type="primary"
                                            className="primary-button"
                                            style={{ display: "flex", alignItems: "center", marginBottom: "2rem" }}
                                            onClick={() => {
                                                setVehicleType("bicycle")
                                                setStep(4)
                                            }}
                                        >
                                            <span style={{ display: "flex", alignItems: 'center', gap: "1.5rem" }}>
                                                <GiJeep size={28} />
                                                <span> SUV </span>
                                            </span>
                                            <BsChevronRight size={18} style={{ marginLeft: "auto" }} />
                                        </Button>

                                        <Button
                                            block
                                            size={"large"}
                                            htmlType='submit'
                                            type="primary"
                                            className="primary-button"
                                            style={{ display: "flex", alignItems: "center", marginBottom: "2rem" }}
                                            onClick={() => {
                                                setVehicleType("bicycle")
                                                setStep(4)
                                            }}
                                        >
                                            <span style={{ display: "flex", alignItems: 'center', gap: "1.5rem" }}>
                                                <FaShuttleVan size={25} />
                                                <span> Micro Bus </span>
                                            </span>
                                            <BsChevronRight size={18} style={{ marginLeft: "auto" }} />
                                        </Button>

                                        <Button
                                            block
                                            size={"large"}
                                            htmlType='submit'
                                            type="primary"
                                            className="primary-button"
                                            style={{ display: "flex", alignItems: "center", marginBottom: "2rem" }}
                                            onClick={() => {
                                                setVehicleType("bicycle")
                                                setStep(4)
                                            }}
                                        >
                                            <span style={{ display: "flex", alignItems: 'center', gap: "1.5rem" }}>
                                                <BsBicycle size={25} />
                                                <span> Bicycle </span>
                                            </span>
                                            <BsChevronRight size={18} style={{ marginLeft: "auto" }} />
                                        </Button>
                                    </>
                                )}

                                {step === 4 && (
                                    <>
                                        {vehicleType === "car" && (
                                            <>
                                                <h4 style={{ fontSize: '2.6rem', fontWeight: 'bold', textAlign: "center", marginBottom: '0rem' }}>Sedan Car Registration </h4>
                                                <span style={{ display: 'block', textAlign: 'center' }}>(Please provide all information in English Language)</span>
                                                <CarRegistrationForm
                                                    city={city}
                                                    vehicleType={vehicleType}
                                                    cellNumber={cellNumber}
                                                />
                                            </>
                                        )}

                                        {vehicleType === "bike" && (
                                            <>
                                                <h4 style={{ fontSize: '2.6rem', fontWeight: 'bold', textAlign: "center", marginBottom: '.5rem' }}> বাইক রেজিস্ট্রেশন </h4>
                                                <span style={{ display: 'block', textAlign: 'center' }}>(অনুগ্রহপূর্বক সকল তথ্য ইংরেজিতে দিন)</span>
                                                <BikeRegistrationForm
                                                    city={city}
                                                    vehicleType={vehicleType}
                                                    cellNumber={cellNumber}
                                                />
                                            </>
                                        )}

                                        {vehicleType === "rickshaw" && (
                                            <>
                                                <h4 style={{ fontSize: '2.6rem', fontWeight: 'bold', textAlign: "center", marginBottom: '.5rem' }}> রিকশা রেজিস্ট্রেশন </h4>
                                                <span style={{ display: 'block', textAlign: 'center' }}>(অনুগ্রহপূর্বক সকল তথ্য ইংরেজিতে দিন)</span>

                                                <RickshawRegistration
                                                    city={city}
                                                    vehicleType={vehicleType}
                                                    cellNumber={cellNumber}
                                                />
                                            </>
                                        )}

                                        {vehicleType === "bicycle" && (
                                            <>
                                                <h4 style={{ fontSize: '2.6rem', fontWeight: 'bold', textAlign: "center", marginBottom: '.5rem' }}> বাইসাইকেল রেজিস্ট্রেশন </h4>
                                                <span style={{ display: 'block', textAlign: 'center' }}>(অনুগ্রহপূর্বক সকল তথ্য ইংরেজিতে দিন)</span>
                                                <BiCycleRegistrationForm
                                                    city={city}
                                                    vehicleType={vehicleType}
                                                    cellNumber={cellNumber}
                                                />
                                            </>
                                        )}
                                    </>
                                )}

                            </div>
                        </Col>

                        {/* <Col lg={12} xs={24} sm={24}>
                            <div className="registration_benefits">
                                <span className="title-large" style={{ marginBottom: '2rem' }}>কেন আমাদের প্ল্যাটফর্মে জয়েন করবেন?</span>
                                <div className="point">
                                    <div className="point__bullet">১</div>
                                    <div className="fc">
                                        <span className="title-medium">প্রথম ৩ মাসে কোন কমিশন নেই</span>
                                        <span className="para-regular">পাঠাও- তে জয়েন করা মানেই দেশের সর্বোচ্চ আয়ের প্ল্যাটফর্মের সাথে থাকা। তাই আর অপেক্ষা কিসের? বেশি বেশি আয় করতে জয়েন করুন জলদি!</span>
                                    </div>
                                </div>
                                <div className="point">
                                    <div className="point__bullet">২</div>
                                    <div className="fc">
                                        <span className="title-medium">প্রথম ৩ মাসে কোন কমিশন নেই</span>
                                        <span className="para-regular">পাঠাও- তে জয়েন করা মানেই দেশের সর্বোচ্চ আয়ের প্ল্যাটফর্মের সাথে থাকা। তাই আর অপেক্ষা কিসের? বেশি বেশি আয় করতে জয়েন করুন জলদি!</span>
                                    </div>
                                </div>
                                <div className="point">
                                    <div className="point__bullet">৩</div>
                                    <div className="fc">
                                        <span className="title-medium">প্রথম ৩ মাসে কোন কমিশন নেই</span>
                                        <span className="para-regular">পাঠাও- তে জয়েন করা মানেই দেশের সর্বোচ্চ আয়ের প্ল্যাটফর্মের সাথে থাকা। তাই আর অপেক্ষা কিসের? বেশি বেশি আয় করতে জয়েন করুন জলদি!</span>
                                    </div>
                                </div>
                                <div className="point">
                                    <div className="point__bullet">৪</div>
                                    <div className="fc">
                                        <span className="title-medium">প্রথম ৩ মাসে কোন কমিশন নেই</span>
                                        <span className="para-regular">পাঠাও- তে জয়েন করা মানেই দেশের সর্বোচ্চ আয়ের প্ল্যাটফর্মের সাথে থাকা। তাই আর অপেক্ষা কিসের? বেশি বেশি আয় করতে জয়েন করুন জলদি!</span>
                                    </div>
                                </div>
                            </div>
                        </Col> */}
                    </Row>
                </section>
            </div>
        </Layout >
    );
};

export default RiderRegistration;



