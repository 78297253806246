import React from 'react';
import ViewPdf from "../Components/ViewPDF";
import Layout from "../Components/Layout";

const RideShare2017 = () => {
    return (
        <Layout>
            <div>
                <ViewPdf
                    url={'https://leanexa-storage.s3.ap-south-1.amazonaws.com/transporter-static-temp/Gazette+of+Ride-Sharing-Service-Guideline-2017+(1).pdf'}/>
            </div>
        </Layout>
    );
};

export default RideShare2017;