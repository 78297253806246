import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

//importing views
import Landing from './Views/Landing';
import RiderRegistration from './Views/RiderRegistration';
import Privacy from './Views/Privacy';
import CookiePolicy from './Views/CookiePolicy';
import TermsOfService from './Views/TermsOfService';
import GoTogetherPage from './Views/GoTogetherPage';
import CorporateSignUp from './Views/CorporateSignUp/CorporateSignUp';
import FranchiseSignUp from './Views/FranchiseSignUp/FranchiseSignUp';
import ContactUs from './Views/ContactUs/ContactUs';
import RideShare2017 from "./Views/RideShare2017";
import RoadSignManual from "./Views/RoadSignManual";
import AboutUs from "./Views/AboutUs/AboutUs";
import RegistrationGuide from "./Views/RegistrationGuide";
import Covid19 from "./Views/Covid19";

export default function Hello() {
    return (
        <Router>
            <Route path='/' exact component={Landing}/>
            <Route path='/rider-registration' exact component={RiderRegistration}/>
            <Route path='/terms-of-service' exact component={TermsOfService}/>
            <Route path='/privacy-notice' exact component={Privacy}/>
            <Route path='/privacy' exact component={Privacy}/>
            <Route path='/cookie-policy' exact component={CookiePolicy}/>
            <Route path='/go-together' exact component={GoTogetherPage}/>
            <Route path='/corporate-signup' exact component={CorporateSignUp}/>
            <Route path='/franchise-signup' exact component={FranchiseSignUp}/>
            <Route path='/contact-us' exact component={ContactUs}/>
            <Route path='/ride-share-2017' exact component={RideShare2017}/>
            <Route path='/road-sign-manual' exact component={RoadSignManual}/>
            <Route path='/covid-19' exact component={Covid19}/>
            <Route path='/registration-guide' exact component={RegistrationGuide}/>
            <Route path='/about-us' exact component={AboutUs}/>
        </Router>
    );
}
