import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

//importing all components
import Footer from "./Footer";
import { Col, Drawer, Row } from "antd";

//importing icons
import { RiMotorbikeFill, RiMapPinRangeFill, RiCloseCircleLine } from "react-icons/ri";
import { IoIosHelpCircle } from "react-icons/io";
import { CgMoreO } from "react-icons/cg";
import { GiHamburgerMenu } from "react-icons/gi";

const Layout = (props) => {
    const location = useLocation();

    const [mobileMenu, setMobileMenu] = useState(false);
    const [showHeaderFooter, setShowHeaderFooter] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (window.innerWidth <= 1035) {
            setMobileMenu(true);
        } else {
            setMobileMenu(false);
        }
        if (window.innerWidth <= 576) {
            setShowHeaderFooter(false);
        } else {
            setShowHeaderFooter(true);
        }
    }, []);

    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    return (
        <>
            {/* top bar */}
            <div className="top_bar">
                <div className="container">
                    <ul>
                        <li>
                            <Link to="/"> Buy/Sell Car </Link>
                        </li>

                        <li>
                            <Link to="/corporate-signup">  Corporate Sign Up</Link>
                        </li>

                        <li>
                            <Link to="/franchise-signup">  Franchise Sign Up</Link>
                        </li>

                        <li>
                            <Link to="/"> Offers</Link>
                        </li>
                        <li>
                            <Link to="/"> Support</Link>
                        </li>
                    </ul>
                </div>
            </div>

            {/* Header Section */}
            {location.pathname === "/rider-registration" && !showHeaderFooter ? null : (
                <>
                    <div className="header">
                        <div className="container">
                            <Row style={{ alignItems: "center" }}>
                                <Col span={6}>
                                    <div className="landing-header__left">
                                        <Link to="/">
                                            <img src="/Logo_large.svg" alt="logo" style={{ height: "4rem" }} />
                                        </Link>
                                    </div>
                                </Col>
                                <Col span={18}>
                                    <div className="landing-header__right">
                                        {mobileMenu ? (
                                            <div className="mobile_menu_wrapper">
                                                <GiHamburgerMenu size={25} fill="black" style={{ display: "flex" }} onClick={showDrawer} />
                                            </div>
                                        ) : (
                                            <ul>
                                                <Link to="rider-registration">
                                                    <li>
                                                        <RiMotorbikeFill />
                                                        Drive With Transporter
                                                    </li>
                                                </Link>
                                                <Link to="/go-together">
                                                    <li>
                                                        <RiMapPinRangeFill />
                                                        Go Together
                                                    </li>
                                                </Link>
                                                <a href="/#how-to-use">
                                                    <li>
                                                        <IoIosHelpCircle />
                                                        How to use
                                                    </li>
                                                </a>
                                                {/* <li>
                                                    <CgMoreO />
                                                    More
                                                </li> */}
                                            </ul>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </>
                // <header className="landing-header">
                //     <div className="landing-header__left">
                //         <Link to="/">
                //             <img src="/Logo_large.svg" alt="logo" style={{ height: "4rem" }} />
                //         </Link>
                //     </div>

                // </header>
            )}

            <Drawer placement="right" closeIcon={<RiCloseCircleLine size={20} />} onClose={onClose} visible={visible}>
                <ul className="mobile_menu">
                    <Link to="rider-registration">
                        <li>
                            <RiMotorbikeFill size={20} />
                            আয় করুন
                        </li>
                    </Link>
                    <a href="/#services">
                        <li onClick={onClose}>
                            <RiMapPinRangeFill size={20} />
                            সার্ভিসসমূহ
                        </li>
                    </a>
                </ul>
            </Drawer>

            <div className="main">
                {/* Child Components */}
                {props.children}
                {location.pathname === "/rider-registration" && !showHeaderFooter ? null : <Footer />}
            </div>
        </>
    );
};

export default Layout;
