import Layout from '../../Components/Layout';
import styles from "./ContactUs.module.scss";
import { Button, Form, Input, Row, Col, Select, message } from 'antd';

const ContactUs = () => {
    const [form] = Form.useForm();
    // defining submitContactUs function
    const submitContactUs = (values) => {
        console.log(values)
        message.success("Your query is recorded!")
        form.resetFields()
    }
    return (
        <Layout>
            <div className={styles.contactUs}>
                <div className="container">
                    <div className={styles.card}>
                        <h2 className={styles.contactUs_title}>How May We Help You</h2>
                        <Form
                            name="contact_us"
                            onFinish={submitContactUs}
                            layout="vertical"
                            form={form}
                        >
                            <Row>
                                {/* <Col span={24}>
                                    <Form.Item
                                        name="phone"
                                        label="Phone Number"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your phone number!',
                                            },
                                        ]}
                                    >
                                        <Input type="number" placeholder="01710000000" />
                                    </Form.Item>
                                </Col> */}
                                <Col span={24}>
                                    <Form.Item
                                        name="email"
                                        label="Your Email Address"
                                        rules={[
                                            {
                                                type: 'email',
                                                message: 'The input is not valid E-mail!',
                                            },
                                            {
                                                required: true,
                                                message: 'Please input your E-mail!',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="yourname@yourdomain.com" />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name="category"
                                        label="Select a Category"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select a category!',
                                            },
                                        ]}
                                    >
                                        <Select placeholder="Select your category">
                                            <Select.Option value="general_question">General Question</Select.Option>
                                            <Select.Option value="payment">Payment</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="detailed_message"
                                        label="Detailed Message"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Enter detailed message',
                                            },
                                        ]}
                                    >
                                        <Input.TextArea rows={3} placeholder='I want to talk about....' />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item >
                                        <Button type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>

                </div>

            </div>
        </Layout>
    );
}

export default ContactUs;