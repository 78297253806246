import React from 'react';
import ViewPdf from "../Components/ViewPDF";
import Layout from "../Components/Layout";

const RoadSignManual = () => {
    return (
        <Layout>
            <div>
                <ViewPdf
                    url={'https://leanexa-storage.s3.ap-south-1.amazonaws.com/transporter-static-temp/Road+Sign+Manual+Volume-1.pdf'}/>
            </div>
        </Layout>
    );
};

export default RoadSignManual;