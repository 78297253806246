import moment from "moment";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

// importing icons
import {
    Row,
    Col,
    Form,
    Input,
    Button,
    DatePicker,
    Select,
    Progress,
    message,
} from "antd";

// importing axios
import axios from "../../api/axios";

// importing icons
import { FaRegCheckCircle } from "react-icons/fa";

const BiCycleRegistrationForm = (props) => {
    const [step, setStep] = useState(1);
    const [xhr, setXhr] = useState(null);
    const [images, setImages] = useState([]);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [step1Values, setStep1Values] = useState({});
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);


    //defining imageUploadHandler function
    const imageUploadHandler = async (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image");

            let formData = new FormData();

            formData.append("image", e.target.files[i]);

            const res = await axios.post("/misc/v1/image-upload", formData, {
                withCredentials: true,
                onUploadProgress: (progressEvent) => {
                    const percentage = Math.round(
                        (progressEvent.loaded / progressEvent.total) * 100
                    );
                    setUploadPercentage(percentage);
                    console.log(percentage);
                },
            });
            setImages([res.data.secureUrl]);
            setXhr(null);
        }
    };

    const ProceedNextWithData = async (values) => {
        if (images.length < 1) {
            message.error("কমপক্ষে একটি ছবি আপলোড করুন");
            return;
        }
        if (images.length > 1) {
            message.error("মাত্র একটি ছবি আপলোড করুন");
            return;
        }

        values.cellNumber = props.cellNumber;
        values.city = props.city;
        values.vehicleType = props.vehicleType;
        values.dp = images[0];
        values.dateOfBirth =
            values.dateOfBirth !== "" ? moment(values.dateOfBirth).format() : null;

        try {
            setLoading1(true);

            const res = await axios.post("/misc/v1/send-otp", {
                cellNumber: props.cellNumber,
            });

            values.hashedOtp = res.data.hashedOtp;

            setStep1Values(values);
            setStep(2);
        } catch (error) {
            console.log(error);
            message.error("Something went wrong!")
        } finally {
            setLoading1(false);
        }
    };

    const handleBiCycleRegistration = async (values) => {
        try {
            setLoading2(true);
            let payload = {
                ...step1Values,
                ...values,
            };
            console.log(payload);

            // first send request to user registration endpoint
            let response;
            try {
                response = await axios.post("/users/v1/register", {
                    cellNumber: payload.cellNumber,
                    name: payload.name,
                    hashedOtp: payload.hashedOtp,
                    userGivenOtp: payload.userGivenOtp,
                });
            } catch (error) {
                console.log(error);
                message.error(error.response.data.msg)
                return;
            }

            delete payload.hashedOtp;
            delete payload.userGivenOtp;

            // next send rider registration endpoint
            await axios.post(
                "/users/v1/rider/details-submission",
                {
                    user: response.data.user._id,
                    ...payload,
                },
                {
                    headers: {
                        accesstoken: response.data.token,
                    },
                }
            );
            setStep(3)
        } catch (error) {
            console.log(error);
            message.error("Something went wrong!")
        } finally {
            setLoading2(false);
        }
    };

    return (
        <>
            {step === 1 && (
                <Form
                    layout="vertical"
                    onFinish={ProceedNextWithData}
                    style={{ marginTop: "2rem" }}
                    scrollToFirstError
                >
                    <Row>
                        <span className="form_headline"> ব্যক্তিগত তথ্য </span>

                        <Col span={24}>
                            <div style={{ margin: "0 0 2rem 0" }}>
                                <label style={{ fontWeight: 600 }} htmlFor="Dp">
                                    প্রোফাইল পিকচার
                                </label>
                                <div className="file-upload">
                                    <div className="image-upload-wrap">
                                        <input
                                            className="file-upload-input"
                                            type="file"
                                            multiple={false}
                                            onChange={imageUploadHandler}
                                        />
                                        <div className="drag-text">
                                            {xhr === "image" ? (
                                                <div className="upload_progress_wrapper">
                                                    <Progress percent={uploadPercentage} />
                                                </div>
                                            ) : (
                                                <h3>ছবি আপলোড করুন</h3>
                                            )}{" "}
                                        </div>
                                    </div>
                                    {images.length > 0 ? (
                                        <div style={{ marginTop: "2rem" }} className="h-center">
                                            {images.map((image, i) => (
                                                <img
                                                    className="uploaded_image"
                                                    key={i}
                                                    src={image}
                                                    alt="dp"
                                                />
                                            ))}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="পুরো নাম (জাতীয় পরিচয় পত্র অনুসারে)"
                                rules={[{ required: true, message: "আপনার পুরো নাম দিন" }]}
                            >
                                <Input placeholder="Shamim Ferdous" type="text" size="large" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name="nid"
                                label="জাতীয় পরিচয় পত্র নম্বর"
                                rules={[
                                    {
                                        required: true,
                                        message: "আপনার জাতীয় পরিচয় পত্র নম্বর দিন",
                                    },
                                ]}
                            >
                                <Input placeholder="8701550140" type="number" size="large" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name="address"
                                label="বিস্তারিত ঠিকানা"
                                rules={[
                                    { required: true, message: "আপনার বিস্তারিত ঠিকানা দিন" },
                                ]}
                            >
                                <Input.TextArea placeholder="House No 126/69, Road No 2, Adabor, Mohammadpur, Dhaka" type="text" size="large" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name="gender"
                                label="লিঙ্গ"
                                rules={[{ required: true, message: "লিঙ্গ নির্বাচন করুন" }]}
                            >
                                <Select placeholder="পুরুষ" size="large">
                                    <Select.Option value="male"> পুরুষ </Select.Option>
                                    <Select.Option value="female"> মহিলা </Select.Option>
                                    <Select.Option value="other"> অন্যান্য </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name="dateOfBirth"
                                label="জন্ম তারিখ"
                                rules={[
                                    { required: true, message: "আপনার জন্ম তারিখ নির্বাচন করুন" },
                                ]}
                            >
                                <DatePicker
                                    size="large"
                                    allowClear={false}
                                    style={{ width: "100%" }}
                                    placeholder="1998-03-01"
                                />
                            </Form.Item>
                        </Col>

                        <span>
                            {" "}
                            "পরবর্তী" ক্লিক করে আপনি আমাদের <a href="/">শর্তাবলী</a>র সাথে
                            সম্মত হচ্ছেন।{" "}
                        </span>

                        <Col span={24}>
                            <Form.Item>
                                <Button
                                    block
                                    loading={loading1}
                                    size={"large"}
                                    htmlType="submit"
                                    type="primary"
                                    className="primary-button"
                                    style={{ marginTop: "2rem" }}
                                >
                                    পরবর্তী
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            )}

            {step === 2 && (
                <>
                    <h4 style={{ marginTop: "2rem" }}>
                        আপনার {props.cellNumber} নাম্বার এ একটি 4 সংখ্যার কোড পাঠানো হয়েছে।{" "}
                    </h4>
                    <Form
                        layout="vertical"
                        onFinish={handleBiCycleRegistration}
                        style={{ marginTop: "2rem" }}
                        initialValues={{ car_registration_prefix: "bh" }}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="userGivenOtp"
                                    label="ওটিপি"
                                    rules={[
                                        {
                                            required: true,
                                            message: "আপনার ওটিপি দিন",
                                        },
                                    ]}
                                >
                                    <Input type="number" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <Button
                                        loading={loading2}
                                        block
                                        size={"large"}
                                        htmlType="submit"
                                        type="primary"
                                        className="primary-button"
                                    >
                                        রেজিস্ট্রেশন করুন
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </>
            )}

            {step === 3 && (
                <>
                    <div
                        style={{
                            textAlign: "center",
                            padding: "2.5rem 0",
                        }}
                    >
                        <FaRegCheckCircle size={45} color="var(--color-green)" />
                        <h1 style={{ fontWeight: "bold" }}> অভিনন্দন! </h1>
                        <h2 style={{ fontWeight: "300" }}>
                            {" "}
                            আপনার রেজিস্ট্রেশন সফল হয়েছে। আপনার তথ্য যাচাই করে অতি শীঘ্রই আমরা
                            আপনার সাথে যোগাযোগ করবো।{" "}
                        </h2>
                        <Link to="/">
                            <Button type="primary" size="large">
                                হোমপেজে ফিরে যান
                            </Button>
                        </Link>
                    </div>
                </>
            )}
        </>
    );
};

export default BiCycleRegistrationForm;
