import styles from "./Footer.module.scss";
//importing all icons
import { RiInstagramFill } from "react-icons/ri";
import { ImPhone } from "react-icons/im";
import { FaFacebookF } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { BiBuildingHouse } from "react-icons/bi";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import { GoMail } from "react-icons/go";

const Footer = () => {
    return (
        <footer className="footer-section">
            <div className="container">
                <Row gutter={[16, 16]}>
                    <Col lg={6} md={12} sm={24}>
                        <div className="fc" style={{ alignItems: "flex-start" }}>
                            <img src="/Logo_large.svg" alt="logo" style={{ height: "3rem" }} />
                            <span style={{ marginTop: ".5rem", fontSize: "1.4rem" }}>হাতের মুঠোয় অনডিমান্ড সার্ভিস</span>
                            <div className="landing-social-box">
                                <a href="/">
                                    <FaFacebookF size={20} />
                                </a>
                                <a href="/">
                                    <RiInstagramFill size={20} />
                                </a>
                                <a href="/">
                                    <RiInstagramFill size={20} />
                                </a>
                                <a href="/">
                                    <RiInstagramFill size={20} />
                                </a>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} md={12} sm={24}>
                        <div>
                            <ul className="footer-list" style={{ padding: 0, cursor: 'pointer' }}>
                                <a href="/ride-share-2017">
                                    <li style={{cursor: 'pointer'}}>Ride Sharing Service Guideline</li>
                                </a>
                                <a href="/road-sign-manual">
                                    <li style={{cursor: 'pointer'}}>Traffic Signs & Rules</li>
                                </a>
                                <a href="/covid-19">
                                    <li style={{cursor:'pointer'}}>Transporter & Covid 19</li>
                                </a>
                                <div className={styles.subscribe_to_newsletter}>
                                    <span style={{ marginBottom: "1rem", display: "block" }}>Subscribe to Newsletter</span>
                                    <form
                                        className={styles.newsletter_form}
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            // toast.success("Newsletter subscription was successful!");
                                        }}
                                    >
                                        <input type="email" required placeholder="Your Email" />
                                        <button>Subscribe</button>
                                    </form>
                                    {/*<a target="_blank" href="mailto:ceo@nexa.store" rel="noreferrer">*/}
                                    {/*    <span style={{ color: "var(--color-white)", fontSize: 12, marginTop: 10, display: "flex", flexDirection: "row", alignItems: "center" }}>*/}
                                    {/*        <GoMail size={20} style={{ color: "var(--color-white)", marginRight: 5 }} />*/}
                                    {/*        Write to CEO*/}
                                    {/*    </span>*/}
                                    {/*</a>*/}
                                </div>
                            </ul>
                        </div>
                    </Col>
                    <Col lg={6} md={12} sm={24}>
                        <div>
                            <ul className="footer-list" style={{ padding: 0 }}>
                                <li>Important Links</li>
                                <li>
                                    {" "}
                                    <Link to="/about-us"> About Us </Link>{" "}
                                </li>
                                <li>
                                    {" "}
                                    <Link to="/contact-us"> Contact Us </Link>{" "}
                                </li>
                                <li>
                                    {" "}
                                    <Link to="/privacy-notice"> Privacy Notice </Link>{" "}
                                </li>
                                <li>
                                    {" "}
                                    <Link to="/cookie-policy"> Cookie Policy </Link>{" "}
                                </li>
                                <li>
                                    {" "}
                                    <Link to="/terms-of-service">Terms of Service </Link>{" "}
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col lg={6} md={12} sm={24}>
                        <div>
                            <ul className="footer-list" style={{ padding: 0 }}>
                                <li>How to Reach Us</li>
                                <span className="landing-text-w-icon">
                                    <BiBuildingHouse size={20} />
                                    C/O, Morium Begum
Level-1, Village-East Botlagari (Fokir Para), PS-Syedpur, PO-Wapda,
                                    <br/>
                                    9.00 AM - 5.00 PM (Friday Weekend)
                                </span>
                                <span className="landing-text-w-icon">
                                    <ImPhone size={20} />
                                    02224470422
                                </span>
                                <span className="landing-text-w-icon">
                                    <MdEmail size={20} />
                                    contact@transporter.com.bd
                                </span>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className={styles.copyright}>
                <span>© 2019-2022 Nexa Global Holdings Limited, and/or its affiliates and subsidiaries.</span>
            </div>
        </footer>
    );
};

export default Footer;
