import Layout from "../Components/Layout";
import { BackTop } from 'antd';

const CookiePolicy = () => {
    return (
        <Layout>
            <div style={{ paddingTop: "2rem", paddingBottom: "2rem" }} className="container">
                <h1>COOKIE POLICY</h1>
                <p>Last updated April 26, 2022</p>

                <p>
                    This Cookie Policy explains how Nexa Global Holdings Limited ("Company", "we", "us", and "our") uses cookies and similar technologies to recognize you when you visit our websites at http://transporter.com.bd, ("Websites"). It explains what these technologies are and why we use
                    them, as well as your rights to control our use of them.
                </p>

                <p>In some cases we may use cookies to collect personal information, or that becomes personal information if we combine it with other information.</p>

                <h2>
                    {" "}
                    <strong>What are cookies?</strong>{" "}
                </h2>

                <p>Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.</p>

                <p>
                    Cookies set by the website owner (in this case, Nexa Global Holdings Limited) are called "first party cookies". Cookies set by parties other than the website owner are called "third party cookies". Third party cookies enable third party features or functionality to be provided on
                    or through the website (e.g. like advertising, interactive content and analytics). The parties that set these third party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.
                </p>

                <h2>
                    <strong>Why do we use cookies?</strong>
                </h2>

                <p>
                    We use first and third party cookies for several reasons. Some cookies are required for technical reasons in order for our Websites to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies also enable us to track and target the interests of
                    our users to enhance the experience on our Online Properties. Third parties serve cookies through our Websites for advertising, analytics and other purposes. This is described in more detail below.
                </p>

                <p>The specific types of first and third party cookies served through our Websites and the purposes they perform are described below (please note that the specific cookies served may vary depending on the specific Online Properties you visit)</p>


                <h3>
                    <strong>
                        {" "}
                        <u>Essential website cookies: </u>{" "}
                    </strong>
                </h3>

                <p>These cookies are strictly necessary to provide you with services available through our Websites and to use some of its features, such as access to secure areas.</p>

                <div style={{ border: "1px solid #e6e6e6", borderRadius: "3px", padding: "1rem 3rem" }}>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ textAlign: "right" }}>Name: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>__tlbcpv</span>
                                </td>
                            </tr>

                            <tr style={{ verticalAlign: "top" }}>
                                <td style={{ textAlign: "right" }}>Purpose: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>Used to record unique visitor views of the consent banner.</span>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "right" }}>Provider: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>.termly.io</span>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "right" }}>Service: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>Termly  <a href="https://termly.io/our-privacy-policy/" target="_blank" rel="noopener noreferrer">View Service Privacy Policy  </a> </span>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "right" }}>Country: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>United States</span>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "right" }}>Type: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>http_cookie</span>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "right" }}>Expires in: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>1 year</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br />
                <h3>
                    <strong>
                        {" "}
                        <u>Analytics and customization cookies: </u>{" "}
                    </strong>
                </h3>

                <p>These cookies collect information that is used either in aggregate form to help us understand how our Websites are being used or how effective our marketing campaigns are, or to help us customize our Websites for you.</p>

                <div style={{ border: "1px solid #e6e6e6", borderRadius: "3px", padding: "1rem 3rem" }}>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ textAlign: "right" }}>Name: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>_ga</span>
                                </td>
                            </tr>

                            <tr style={{ verticalAlign: "top" }}>
                                <td style={{ textAlign: "right" }}>Purpose: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>It records a particular ID used to come up with data about website usage by the user. It is a HTTP cookie that expires after 2 years.</span>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "right" }}>Provider: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>.nexa.store</span>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "right" }}>Service: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>Google Analytics  <a href="https://policies.google.com/technologies/ads" target="_blank" rel="noopener noreferrer">View Service Privacy Policy  </a> </span>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "right" }}>Country: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>India</span>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "right" }}>Type: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>http_cookie</span>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "right" }}>Expires in: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>1 year 11 months 29 days</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br />
                <div style={{ border: "1px solid #e6e6e6", borderRadius: "3px", padding: "1rem 3rem" }}>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ textAlign: "right" }}>Name: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>_gid</span>
                                </td>
                            </tr>

                            <tr style={{ verticalAlign: "top" }}>
                                <td style={{ textAlign: "right" }}>Purpose: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>Keeps an entry of unique ID which is then used to come up with statistical data on website usage by visitors. It is a HTTP cookie type and expires after a browsing session.</span>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "right" }}>Provider: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>.nexa.store</span>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "right" }}>Service: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>Google Analytics  <a href="https://policies.google.com/technologies/ads" target="_blank" rel="noopener noreferrer">View Service Privacy Policy  </a> </span>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "right" }}>Country: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>India</span>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "right" }}>Type: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>http_cookie</span>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "right" }}>Expires in: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>1 day</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <br />

                <div style={{ border: "1px solid #e6e6e6", borderRadius: "3px", padding: "1rem 3rem" }}>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ textAlign: "right" }}>Name: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>_ga_#</span>
                                </td>
                            </tr>

                            <tr style={{ verticalAlign: "top" }}>
                                <td style={{ textAlign: "right" }}>Purpose: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>Used to distinguish individual users by means of designation of a randomly generated number as client identifier, which allows calculation of visits and sessions</span>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "right" }}>Provider: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>.nexa.store</span>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "right" }}>Service: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>Google Analytics  <a href="https://policies.google.com/technologies/ads" target="_blank" rel="noopener noreferrer">View Service Privacy Policy  </a> </span>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "right" }}>Country: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>India</span>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "right" }}>Type: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>http_cookie</span>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "right" }}>Expires in: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>1 year 11 months 29 days</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br />
                <div style={{ border: "1px solid #e6e6e6", borderRadius: "3px", padding: "1rem 3rem" }}>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ textAlign: "right" }}>Name: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>_gat#</span>
                                </td>
                            </tr>

                            <tr style={{ verticalAlign: "top" }}>
                                <td style={{ textAlign: "right" }}>Purpose: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>Enables Google Analytics regulate the rate of requesting. It is a HTTP cookie type that lasts for a session.</span>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "right" }}>Provider: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>.nexa.store</span>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "right" }}>Service: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>Google Analytics  <a href="https://policies.google.com/technologies/ads" target="_blank" rel="noopener noreferrer">View Service Privacy Policy  </a> </span>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "right" }}>Country: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>India</span>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "right" }}>Type: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>http_cookie</span>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ textAlign: "right" }}>Expires in: </td>
                                <td style={{ marginLeft: "1rem", display: "inline-block" }}>
                                    <span>1 minute</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <br />

                <h2> <strong> What about other tracking technologies, like web beacons? </strong></h2>

                <p>Cookies are not the only way to recognize or track visitors to a website. We may use other, similar technologies from time to time, like web beacons (sometimes called "tracking pixels" or "clear gifs"). These are tiny graphics files that contain a unique identifier that enable us to recognize when someone has visited our Websites or opened an e-mail including them. This allows us, for example, to monitor the traffic patterns of users from one page within a website to another, to deliver or communicate with cookies, to understand whether you have come to the website from an online advertisement displayed on a third-party website, to improve site performance, and to measure the success of e-mail marketing campaigns. In many instances, these technologies are reliant on cookies to function properly, and so declining cookies will impair their functioning.</p>

                <h2> <strong>Do you serve targeted advertising?</strong> </h2>
                <p>Third parties may serve cookies on your computer or mobile device to serve advertising through our Websites. These companies may use information about your visits to this and other websites in order to provide relevant advertisements about goods and services that you may be interested in. They may also employ technology that is used to measure the effectiveness of advertisements. This can be accomplished by them using cookies or web beacons to collect information about your visits to this and other sites in order to provide relevant advertisements about goods and services of potential interest to you. The information collected through this process does not enable us or them to identify your name, contact details or other details that directly identify you unless you choose to provide these.</p>

                <h2><strong>How often will you update this Cookie Policy?</strong></h2>
                <p>We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal or regulatory reasons. Please therefore re-visit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.</p>
                <p>The date at the top of this Cookie Policy indicates when it was last updated.</p>

                <h2><strong>Where can I get further information?</strong></h2>
                <p>If you have any questions about our use of cookies or other technologies, please email us at <a href="mailto:contact@transporter.com.bd">contact@transporter.com.bd</a>  or by post to:</p>
                <div>Shamim Ferdous</div>
                <div>Co-founder and CTO</div>
                <div>Level-04, House-20, Road-09, Sector-13, PS-Uttara West, PO-Uttara</div>
                <div>Dhaka, Bangladesh</div>
                <div>Phone: <a href="tel:+8802224470422">+8802224470422</a> </div>
            </div>
            <BackTop />
        </Layout>
    );
}

export default CookiePolicy;