import styles from './FranchiseSignUp.module.scss';
import { useState } from 'react';
import Layout from '../../Components/Layout';
import { Form, Input, Row, Col, Button, Radio, Select } from 'antd';

const FranchiseSignUp = () => {
    const [form] = Form.useForm();
    const [type, setType] = useState('individual');

    //defining onFinishHandler function
    const onFinishHandler = (formData) => {
        console.log(formData);
    };

    return (
        <Layout>
            <section className='main-container register_heading' style={{ backgroundColor: '#DFF7F9', padding: '4rem 0', borderRadius: 3 }}>
                {/* <span className="h-center" style={{ fontSize: '2.2rem', fontWeight: 'bold' }}>Banner</span> */}
                <h2 style={{ fontSize: '3.5rem', lineHeight: 1 }} className='title'>
                    Banner
                </h2>
            </section>

            <section style={{ backgroundColor: 'var(--color-grey-light-2)', width: '100%', display: 'flex', justifyContent: 'center' }}>
                <section className={'main-container ' + styles.form}>
                    <h2 className={styles.title}>Franchise Sign Up</h2>
                    <Radio.Group
                        value={type}
                        buttonStyle='solid'
                        style={{ marginBottom: '4rem' }}
                        onChange={(e) => {
                            setType(e.target.value);
                            console.log(e.target.value);
                        }}
                    >
                        <Radio.Button value='individual'>Individual</Radio.Button>
                        <Radio.Button value='company'>Company</Radio.Button>
                    </Radio.Group>
                    {type === 'individual' ? (
                        <Form form={form} layout='vertical' onFinish={onFinishHandler} autoComplete='off' style={{ width: '100%' }}>
                            <Row style={{ width: '100%' }} gutter={[16, 16]}>
                                <Col span={12}>
                                    <Form.Item name='contactPersonsName' label='Name' rules={[{ required: true, message: `Name is required.` }]}>
                                        <Input placeholder='John Doe' type='text' size='medium' />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item name='contactPersonsPhoneNumber' label='Phone Number' rules={[{ required: true, message: `Phone Number is required.` }]}>
                                        <Input placeholder='01774XXXXXX' type='text' size='medium' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='nidNumber' label='NID Number' rules={[{ required: true, message: `NID Number is required.` }]}>
                                        <Input placeholder='123 XXX XXXX' type='text' size='medium' />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item name='contactPersonsEmail' label='Email' rules={[{ required: true, message: `Email is required.` }]}>
                                        <Input placeholder='johndoe@example.com' type='text' size='medium' />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item name='contactPersonsAddress' label='Address' rules={[{ required: true, message: `Address is required.` }]}>
                                        <Input.TextArea placeholder='H/N 20, Road 9, Sector 13, Uttara, Dhaka-1230' type='text' size='medium' autoSize />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item name='areaOfOperation' label='Area of Operation' rules={[{ required: true, message: `Area of Operation is required.` }]}>
                                        <Select>
                                            <Select.Option value={'Dhaka'}>Dhaka</Select.Option>
                                            <Select.Option value={'Coxs Bazar'}>Cox's Bazar</Select.Option>
                                            <Select.Option value={'Nilphamari'}>Nilphamari</Select.Option>
                                            <Select.Option value={'Dinajpur'}>Dinajpur</Select.Option>
                                            <Select.Option value={'Thakurgaon'}>Thakurgaon</Select.Option>
                                            <Select.Option value={'Panchagarh'}>Panchagarh</Select.Option>
                                            <Select.Option value={'Rangpur'}>Rangpur</Select.Option>
                                            <Select.Option value={'Kurigram'}>Kurigram</Select.Option>
                                            <Select.Option value={'Lalmonirhat'}>Lalmonirhat</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={24} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                    <Button type='primary' style={{ width: '25%' }} htmlType='submit'>
                                        Sign Up
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    ) : (
                        <Form form={form} layout='vertical' onFinish={onFinishHandler} autoComplete='off' style={{ width: '100%' }}>
                            <Row style={{ width: '100%' }} gutter={[16, 16]}>
                                <Col span={12}>
                                    <Form.Item name='companyName' label='Company Name' rules={[{ required: true, message: 'Company Name is required.' }]}>
                                        <Input placeholder='Nexa Global Holdings Limited' type='text' size='medium' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='companyAddress' label='Company Address' rules={[{ required: true, message: 'Company Address is required.' }]}>
                                        <Input.TextArea placeholder='H/N 20, Road 9, Sector 13, Uttara, Dhaka-1230' type='text' size='medium' autoSize />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='tradeLicenseNumber' label='Trade License Number' rules={[{ required: true, message: 'Trade License Number is required.' }]}>
                                        <Input placeholder='04XXXXX' type='text' size='medium' />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name='bin' label='Business Identification Number (BIN)' rules={[{ required: true, message: 'Business Identification Number is required.' }]}>
                                        <Input placeholder='000XXXXXX' type='text' size='medium' />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item name='contactPersonsName' label="Contact Person's Name" rules={[{ required: true, message: `Contact Person's Name is required.` }]}>
                                        <Input placeholder='John Doe' type='text' size='medium' />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item name='contactPersonsPhoneNumber' label="Contact Person's Phone Number" rules={[{ required: true, message: `Contact Person's Phone Number is required.` }]}>
                                        <Input placeholder='01774XXXXXX' type='text' size='medium' />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item name='contactPersonsEmail' label="Contact Person's Email" rules={[{ required: true, message: `Contact Person's Email is required.` }]}>
                                        <Input placeholder='johndoe@example.com' type='text' size='medium' />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item name='contactPersonsDesignation' label="Contact Person's Designation" rules={[{ required: true, message: `Contact Person's Designation is required.` }]}>
                                        <Input placeholder='Executive, Business Development' type='text' size='medium' />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item name='areaOfOperation' label='Area of Operation' rules={[{ required: true, message: `Area of Operation is required.` }]}>
                                        <Select>
                                            <Select.Option value={'Dhaka'}>Dhaka</Select.Option>
                                            <Select.Option value={'Coxs Bazar'}>Cox's Bazar</Select.Option>
                                            <Select.Option value={'Nilphamari'}>Nilphamari</Select.Option>
                                            <Select.Option value={'Dinajpur'}>Dinajpur</Select.Option>
                                            <Select.Option value={'Thakurgaon'}>Thakurgaon</Select.Option>
                                            <Select.Option value={'Panchagarh'}>Panchagarh</Select.Option>
                                            <Select.Option value={'Rangpur'}>Rangpur</Select.Option>
                                            <Select.Option value={'Kurigram'}>Kurigram</Select.Option>
                                            <Select.Option value={'Lalmonirhat'}>Lalmonirhat</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={24} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                    <Button type='primary' style={{ width: '25%' }} htmlType='submit'>
                                        Sign Up
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </section>
            </section>
        </Layout>
    );
};

export default FranchiseSignUp;
