//importing components
import Layout from "../Components/Layout";

//importing all icons
import LandingComponent from "../Components/LandingComponent/LandingComponent";

const Landing = () => {
    return (
        <Layout>
            <LandingComponent />
        </Layout>
    );
};

export default Landing;
