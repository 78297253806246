import React from 'react';
import ViewPdf from "../Components/ViewPDF";
import Layout from "../Components/Layout";

const Covid19 = () => {
    return (
        <Layout>
            <div>
                <ViewPdf
                    url={'https://leanexa-storage.s3.ap-south-1.amazonaws.com/nprp_covid-19_v6_18032020.pdf'}/>
            </div>
        </Layout>
    );
};

export default Covid19;