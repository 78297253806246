import { Col, Row } from 'antd';
import styles from './LandingComponent.module.scss';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

const LandingComponent = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 760,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const offers = [
        {
            img: 'https://cms-web.olacabs.com/00000000361.jpeg',
            title: 'Be entertained with Transporter Play',
            subtitle: 'Discover movies and music on your daily ride',
        },
        {
            img: 'https://cms-web.olacabs.com/00000000362.jpeg',
            title: 'Get yourself a Share Pass',
            subtitle: 'Enjoy cheapest rides on the go',
        },
        {
            img: 'https://cms-web.olacabs.com/00000000360.jpeg',
            title: 'Upgrade to Transporter Select',
            subtitle: 'Explore the benefits of Transporter Select',
        },
        {
            img: 'https://cms-web.olacabs.com/00000000363.svg',
            title: 'Sign up for Transporter Corporate',
            subtitle: 'Employee travel management made easy',
        },
    ];

    return (
        <>
            <div className='hero'>
                <section className='landing-section' style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <div className='hero__left'>
                        <div style={{ marginTop: '2rem' }} className='get-started-box'>
                            <a
                                className='get-started-box__item'
                                target={'_blank'}
                                rel='noreferrer'
                                href='https://play.google.com/store/apps/details?id=bd.com.transporter'
                            >
                                <span>অ্যাপ ডাউনলোড করুন</span>
                                <img src='https://pathao.com/bn/wp-content/uploads/sites/6/2019/02/Google-Play-nb.png' alt='' />
                            </a>
                            <Link to='/rider-registration' className='get-started-box__item'>
                                <span>রাইডার হিসেবে আয় করুন</span>
                                <button>রেজিস্ট্রেশন করুন</button>
                            </Link>
                        </div>
                    </div>
                    <div className='hero__left' style={{ justifyContent: 'flex-start' }}>
                        <span className='hero__left__medium-text'> ট্রান্সপোর্টার </span>
                        <span className='hero__left__large-text'>হাতের মুঠোয় অনডিমান্ড সার্ভিস</span>
                        <span className='hero__left__small-text'>ইমারজেন্সি অনডিমান্ড সার্ভিস লাগবে? ট্রান্সপোর্টার আছে আপনার সমস্যার সমাধানে!</span>
                    </div>
                </section>
            </div>
            <div className={styles.offers + ' container'}>
                <Slider {...settings}>
                    {offers.map((offer, i) => (
                        <div key={i}>
                            <div className={styles.slider}>
                                <div className={styles.slider_img}>
                                    <img src={offer.img} alt='img' />
                                </div>
                                <div className={styles.slider_content}>
                                    <h4> {offer.title} </h4>
                                    <label> {offer.subtitle} </label>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>

            <div className='container'>
                <ServiceInfo />
            </div>

            <div className={styles.banner_long}>
                <h2>Ready to ride?</h2>
                <div className={styles.download_app_icons + ' ' + styles.banner_app_icons}>
                    <a href='/'>
                        <img src='https://nexa.store/images/misc/google-play-button.png' alt='play' />
                    </a>
                    <a href='/'>
                        <img src='https://www.olacabs.com/webstatic/img/appstore.png' alt='app' />
                    </a>
                </div>
                <p>
                    Or <Link to={'/rider-registration'}>sign up</Link> to start driving.
                </p>
            </div>

            <div className='container'>
                <WhyTransporter />
            </div>
        </>
    );
};

export default LandingComponent;

// custom arrows
function SamplePrevArrow(props) {
    return <div className={styles.prevArrow} onClick={props.onClick} />;
}
function SampleNextArrow(props) {
    return <div className={styles.nextArrow} onClick={props.onClick} />;
}

function ServiceInfo() {
    const services = [
        {
            img: 'https://i.ibb.co/ZXy7fkL/type-1.jpg',
            title: 'Albatross',
            paragraph:
                'The perfect way to get through your everyday travel needs. City taxis are available 24/7 and you can book and travel in an instant. With rides starting from as low as Rs. 6/km, you can choose from a wide range of options! You can also opt to do your bit for the environment with Transporter Share!',
            cta_img: 'https://i.ibb.co/d5tNPsf/bike.png',
            benefits: [
                {
                    icon: 'https://www.olacabs.com/webstatic/img/service-type/ct_1.svg',
                    text_1: 'AC',
                    text_2: 'Cabs',
                },
                {
                    icon: 'https://www.olacabs.com/webstatic/img/service-type/ct_2.svg',
                    text_1: 'Pocket',
                    text_2: 'Friendly',
                },
                {
                    icon: 'https://www.olacabs.com/webstatic/img/service-type/ct_3.svg',
                    text_1: 'Cashless',
                    text_2: 'Rides',
                },
            ],
        },
        {
            img: 'https://i.ibb.co/wYqxRGS/type-2.jpg',
            title: 'Marlin',
            paragraph:
                'Ride out of town at affordable one-way and round-trip fares with Transporter intercity travel service. Choose from a range of AC cabs driven by top partners, available in 1 hour or book upto 7 days in advance. We have you covered across India with presence in 90+ cities with over 500 one way routes.',
            cta_img: 'https://i.ibb.co/zspGgn3/acSedan.png',
            benefits: [
                {
                    icon: 'https://www.olacabs.com/webstatic/img/service-type/os_1.svg',
                    text_1: 'One-Way',
                    text_2: 'Trips',
                },
                {
                    icon: 'https://www.olacabs.com/webstatic/img/service-type/os_2.svg',
                    text_1: 'Advance',
                    text_2: 'Booking',
                },
                {
                    icon: 'https://www.olacabs.com/webstatic/img/service-type/os_3.svg',
                    text_1: 'Safe',
                    text_2: 'Rides',
                },
            ],
        },
        {
            img: 'https://i.ibb.co/rwHz30T/type-3.jpg',
            title: 'BlackBuck',
            paragraph:
                'With Transporter Rentals you get a cab at your disposal. So be it a  day long business meeting, a shopping trip with your friends or just a day out in a new city, we have you covered. Packages start at 1 hour and can be extended upto 12 hours!',
            cta_img: 'https://i.ibb.co/FB7P3LS/non-Ac-Sedan.png',
            benefits: [
                {
                    icon: 'https://www.olacabs.com/webstatic/img/service-type/rental_1.svg',
                    text_1: 'Hourly',
                    text_2: 'Packages',
                },
                {
                    icon: 'https://www.olacabs.com/webstatic/img/service-type/rental_2.svg',
                    text_1: 'Multiple',
                    text_2: 'Stops',
                },
                {
                    icon: 'https://www.olacabs.com/webstatic/img/service-type/rental_3.svg',
                    text_1: 'Top-Rated',
                    text_2: 'Partners',
                },
            ],
        },
        {
            img: 'https://i.ibb.co/vX0Z9wy/type-4.jpg',
            title: 'Falcon',
            paragraph:
                'With Transporter Rentals you get a cab at your disposal. So be it a  day long business meeting, a shopping trip with your friends or just a day out in a new city, we have you covered. Packages start at 1 hour and can be extended upto 12 hours!',
            cta_img: 'https://i.ibb.co/strTrn0/suv.png',
            benefits: [
                {
                    icon: 'https://www.olacabs.com/webstatic/img/service-type/rental_1.svg',
                    text_1: 'Hourly',
                    text_2: 'Packages',
                },
                {
                    icon: 'https://www.olacabs.com/webstatic/img/service-type/rental_2.svg',
                    text_1: 'Multiple',
                    text_2: 'Stops',
                },
                {
                    icon: 'https://www.olacabs.com/webstatic/img/service-type/rental_3.svg',
                    text_1: 'Top-Rated',
                    text_2: 'Partners',
                },
            ],
        },
        {
            img: 'https://i.ibb.co/j61rTRQ/type-5.jpg',
            title: 'RaceRunner',
            paragraph:
                'With Transporter Rentals you get a cab at your disposal. So be it a  day long business meeting, a shopping trip with your friends or just a day out in a new city, we have you covered. Packages start at 1 hour and can be extended upto 12 hours!',
            cta_img: 'https://i.ibb.co/6sV6jtj/cng.png',
            benefits: [
                {
                    icon: 'https://www.olacabs.com/webstatic/img/service-type/rental_1.svg',
                    text_1: 'Hourly',
                    text_2: 'Packages',
                },
                {
                    icon: 'https://www.olacabs.com/webstatic/img/service-type/rental_2.svg',
                    text_1: 'Multiple',
                    text_2: 'Stops',
                },
                {
                    icon: 'https://www.olacabs.com/webstatic/img/service-type/rental_3.svg',
                    text_1: 'Top-Rated',
                    text_2: 'Partners',
                },
            ],
        },
        {
            img: 'https://i.ibb.co/PQ7JjKp/type-6.jpg',
            title: 'HummingBird',
            paragraph:
                'With Transporter Rentals you get a cab at your disposal. So be it a  day long business meeting, a shopping trip with your friends or just a day out in a new city, we have you covered. Packages start at 1 hour and can be extended upto 12 hours!',
            cta_img: 'https://i.ibb.co/x67386x/microBus.png',
            benefits: [
                {
                    icon: 'https://www.olacabs.com/webstatic/img/service-type/rental_1.svg',
                    text_1: 'Hourly',
                    text_2: 'Packages',
                },
                {
                    icon: 'https://www.olacabs.com/webstatic/img/service-type/rental_2.svg',
                    text_1: 'Multiple',
                    text_2: 'Stops',
                },
                {
                    icon: 'https://www.olacabs.com/webstatic/img/service-type/rental_3.svg',
                    text_1: 'Top-Rated',
                    text_2: 'Partners',
                },
            ],
        },
    ];
    return (
        <section className={styles.serviceInfo}>
            <div>
                <h2 className={styles.service_head}>The fastest and affordable way to ride</h2>
                <label className={styles.service_subhead}>Transporter offers city taxis, inter-city cabs, and local cabs at hourly packages</label>
                <div className={styles.services_wrapper}>
                    <Row gutter={[30, 30]}>
                        {services.map((service, i) => (
                            <Col lg={8} md={12} sm={24} key={i}>
                                <div className={styles.service_card}>
                                    <a href='https://transporter.com.bd/'>
                                        <div className={styles.service_img_holder}>
                                            <div className={styles.service_img}>
                                                <img src={service.img} alt={`service-${i}`} />
                                            </div>
                                            <div className={styles.service_share_type}>
                                                <img width={'70px'} src={service.cta_img} alt={`cta-${i}`} />
                                            </div>
                                        </div>
                                        <div className={styles.info_container}>
                                            <h2 className={styles.info_h2}>{service.title}</h2>
                                            <p className={styles.info_para}>{service.paragraph}</p>

                                            <div className={styles.benefits_list}>
                                                {service.benefits.map((benefit, i) => (
                                                    <div className={styles.benefit_item} key={i}>
                                                        <img src={benefit.icon} className={styles.icon} alt='icon' />
                                                        <span className={styles.icon_text}>
                                                            {benefit.text_1}
                                                            <br />
                                                            {benefit.text_2}
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
        </section>
    );
}

function WhyTransporter() {
    const items = [
        {
            img: 'https://i.ibb.co/3sjgnx1/why-1.jpg',
            title: 'Best Prices',
            subtitle: 'From Sedans and SUVs to Luxury cars for special occasions, we have cabs to suit every pocket',
        },
        {
            img: 'https://i.ibb.co/0ZmPDNQ/why-2.jpg',
            title: 'Safe and Faster Rides',
            subtitle:
                'Verified drivers, an emergency alert button, and live ride tracking are some of the features that we have in place to ensure you a safe travel experience.',
        },
        {
            img: 'https://i.ibb.co/9Z9HCrQ/why-3.jpg',
            title: 'Daily Commute, 24/7',
            subtitle:
                'A membership program with Ola that lets you ride a Prime Sedan at Mini fares, book cabs without peak pricing and has zero wait time',
        },
        {
            img: 'https://i.ibb.co/47pZLWJ/why-4.jpg',
            title: 'Hygiene First',
            subtitle:
                'Play music, watch videos and a lot more with Ola Play! Also stay connected even if you are travelling through poor network areas with our free wifi facility.',
        },
        {
            img: 'https://i.ibb.co/W557ZLS/why-5.jpg',
            title: 'Backup Vehicle',
            subtitle:
                'To travel with the lowest fares choose Ola Share. For a faster travel experience we have Share Express on some fixed routes with zero deviations. Choose your ride and zoom away!',
        },
        {
            img: 'https://i.ibb.co/ZdShRDM/why-6.jpg',
            title: 'Special Corporate Rate',
            subtitle: 'Now go cashless and travel easy. Simply recharge your Ola money or add your credit/debit card to enjoy hassle free payments.',
        },
    ];

    return (
        <div className={styles.why_transporter}>
            <h2 className={styles.why_tr_h2}>Why Transporter?</h2>
            <label className={styles.why_tr_label}>The best way to travel to your destination</label>
            <div className={styles.why_tr_items}>
                <Row gutter={[30, 30]}>
                    {items.map((item, i) => (
                        <Col lg={12} md={24} key={i}>
                            <div className={styles.why_tr_item}>
                                <img src={item.img} className={styles.why_tr_img} alt={`why_transporter_${i}`} />
                                <div className={styles.why_tr_content}>
                                    <h4 className={styles.why_tr_h4}> {item.title} </h4>
                                    <p className={styles.why_tr_p}> {item.subtitle} </p>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    );
}
