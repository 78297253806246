import GoTogetherComponent from "../Components/GoTogetherComponent/GoTogetherComponent";
import Layout from "../Components/Layout";

const GoTogetherPage = () => {
    return (
        <Layout>
            <GoTogetherComponent />
        </Layout>
    );
};

export default GoTogetherPage;
