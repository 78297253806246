import React from 'react';
import Layout from "../../Components/Layout";
import { Col, Row } from "antd";
import styles from './AboutUs.module.scss';

const AboutUs = () => {
    return (
        <Layout>
            <div className={styles.container}>
                <h1 style={{textAlign: 'center', marginBottom: '2rem', fontWeight: 'bold'}}>Key Persons</h1>
                <Row gutter={[20,20]}>
                    <Col lg={6}>
                        <div className={styles.card}>
                            <img
                                src="https://leanexa-storage.s3.ap-south-1.amazonaws.com/transporter-static-temp/3caf2ae6-17b5-45cc-a9b1-b0edac9a89f0.jpg"
                                alt=""/>
                            <b>Shahana Akhtar</b>
                            <span>Chairman</span>
                            <a href="">shahana@transporter.com.bd</a>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className={styles.card}>
                            <img
                                src="/subrata-sarker.png"
                                alt="" style={{height: '381px'}}/>
                            <b>Subrata Kumar Sarker</b>
                            <span>Managing Director & CEO</span>
                            <a href="">subrata@transporter.com.bd</a>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className={styles.card}>
                            <img
                                src="https://leanexa-storage.s3.ap-south-1.amazonaws.com/transporter-static-temp/pp_photo.jpeg"
                                alt=""/>
                            <b>Shamim Ferdous</b>
                            <span>Deputy Managing Director & CTO</span>
                            <a href="">shamim@transporter.com.bd</a>
                        </div>
                    </Col>
                </Row>
            </div>
        </Layout>
    );
};

export default AboutUs;