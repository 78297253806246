import { Link } from "react-router-dom";
import moment from "moment";
import React, { useState, useEffect } from 'react';

// importing components
import {
    Row,
    Col,
    Form,
    Input,
    Button,
    DatePicker,
    Select,
    Progress,
    message,
    Checkbox
} from 'antd';

// importing axios
import axios from '../../api/axios';

// importing icons
import { FaRegCheckCircle } from "react-icons/fa";



const CarRegistrationForm = (props) => {
    const [step, setStep] = useState(1);
    const [xhr, setXhr] = useState(null);
    const [step1Values, setStep1Values] = useState({});
    const [step2Values, setStep2Values] = useState({});
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const [images, setImages] = useState([]);
    const [uploadPercentage, setUploadPercentage] = useState(0);

    const [images2, setImages2] = useState([]);
    const [uploadPercentage2, setUploadPercentage2] = useState(0);

    const [images3, setImages3] = useState([]);
    const [uploadPercentage3, setUploadPercentage3] = useState(0);

    const [images4, setImages4] = useState([]);
    const [uploadPercentage4, setUploadPercentage4] = useState(0);

    const [makeOptions, setMakeOptions] = useState([]);
    const [selectedMakeOption, setSelectedMakeOption] = useState('');
    const [yearOptions, setYearOptions] = useState([]);
    const [modelOptions, setModelOptions] = useState([]);
    const [showDriverUnderPartnerForm, setShowDriverUnderPartnerForm] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);

    useEffect(() => {
        axios.get("/vehicles/v1/filter-options").then((res) => {
            setMakeOptions(res.data.makeOptions);
        })
    }, []);

    const getYearOptions = (makeOption) => {
        axios.get("/vehicles/v1/filter-options", { params: { make: makeOption } }).then((res) => {
            setYearOptions(res.data.yearOptions);
        })
    }

    const getModelOptions = (makeOption, yearOption) => {
        axios.get("/vehicles/v1", { params: { make: makeOption, year: yearOption, type: "car" } }).then((res) => {
            setModelOptions(res.data);
        })
    }


    const dpUploadHandler = async (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image");

            let formData = new FormData();

            formData.append("image", e.target.files[i]);

            const res = await axios.post('/misc/v1/image-upload', formData, {
                withCredentials: true,
                onUploadProgress: (progressEvent) => {
                    const percentage = Math.round((progressEvent.loaded / progressEvent.total * 100))
                    setUploadPercentage(percentage);
                }
            })
            setImages([res.data.secureUrl]);
            setXhr(null);
        }
    };

    const blueBookUploadHandler = async (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image2");

            let formData = new FormData();

            formData.append("image", e.target.files[i]);

            const res = await axios.post('/misc/v1/image-upload', formData, {
                withCredentials: true,
                onUploadProgress: (progressEvent) => {
                    const percentage = Math.round((progressEvent.loaded / progressEvent.total * 100))
                    setUploadPercentage2(percentage);
                }
            })
            setImages2([res.data.secureUrl]);
            setXhr(null);
        }
    };

    const fitnessPaperUploadHandler = async (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image3");

            let formData = new FormData();

            formData.append("image", e.target.files[i]);

            const res = await axios.post('/misc/v1/image-upload', formData, {
                withCredentials: true,
                onUploadProgress: (progressEvent) => {
                    const percentage = Math.round((progressEvent.loaded / progressEvent.total * 100))
                    setUploadPercentage3(percentage);
                }
            })
            setImages3([res.data.secureUrl]);
            setXhr(null);
        }
    };

    const taxTokenUploadHandler = async (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr("image4");

            let formData = new FormData();

            formData.append("image", e.target.files[i]);

            const res = await axios.post('/misc/v1/image-upload', formData, {
                withCredentials: true,
                onUploadProgress: (progressEvent) => {
                    const percentage = Math.round((progressEvent.loaded / progressEvent.total * 100))
                    setUploadPercentage4(percentage);
                }
            })
            setImages4([res.data.secureUrl]);
            setXhr(null);
        }
    };

    const ProceedNextWithData = (values) => {
        if (images.length < 1) {
            message.error("কমপক্ষে একটি ছবি আপলোড করুন")
            return;
        }
        if (images.length > 1) {
            message.error("মাত্র একটি ছবি আপলোড করুন")
            return;
        }
        values.dp = images[0];
        values.dateOfBirth = values.dateOfBirth !== "" ? moment(values.dateOfBirth).format() : null;
        values.drivingLicenseExpiryDate = values.drivingLicenseExpiryDate !== "" ? moment(values.drivingLicenseExpiryDate).format() : null;
        values.vehicleRegistrationNumber = `${values.vehicleRegistrationNumberPrefix}-${values.vehicleRegistrationNumber}`;
        values.driverUnderPartner = showDriverUnderPartnerForm;
        setStep1Values(values);
        setStep(2);
    }

    const ProceedNextWithData2 = async (values) => {
        if (images2.length < 1 || images2.length > 1) {
            message.error("ব্লু বুক এর একটি ছবি আপলোড করুন")
            return;
        }

        if (images3.length < 1 || images3.length > 1) {
            message.error("ফিটনেস পেপার এর একটি ছবি আপলোড করুন")
            return;
        }

        if (images4.length < 1 || images4.length > 1) {
            message.error("ট্যাক্স টোকেন এর একটি ছবি আপলোড করুন")
            return;
        }

        values.cellNumber = props.cellNumber;
        values.city = props.city;
        values.vehicleType = props.vehicleType;
        values.blueBook = images2[0];
        values.fitnessPaper = images3[0];
        values.taxToken = images4[0];

        try {
            setLoading1(true);

            const res = await axios.post("/misc/v1/send-otp", {
                cellNumber: props.cellNumber,
            });

            values.hashedOtp = res.data.hashedOtp;
            setStep2Values(values);
            setStep(3)
        } catch (error) {
            console.log(error);
            message.error("Something went wrong!")
        } finally {
            setLoading1(false);
        }
    }

    const handleCarRegistration = async (values) => {
        try {
            setLoading2(true);
            let payload = {
                ...step1Values,
                ...step2Values,
                ...values
            }
            // console.log(payload);

            // first send request to user registration endpoint
            let response;
            try {
                response = await axios.post("/users/v1/register", {
                    cellNumber: payload.cellNumber,
                    name: payload.name,
                    hashedOtp: payload.hashedOtp,
                    userGivenOtp: payload.userGivenOtp,
                });
            } catch (error) {
                console.log(error);
                message.error(error.response.data.msg)
                return;
            }

            delete payload.hashedOtp;
            delete payload.userGivenOtp;

            // next send rider registration endpoint
            await axios.post(
                "/users/v1/rider/details-submission",
                {
                    user: response.data.user._id,
                    ...payload,
                },
                {
                    headers: {
                        accesstoken: response.data.token,
                    },
                }
            );
            setStep(4)
        } catch (error) {
            console.log(error);
            message.error("Something went wrong!")
        } finally {
            setLoading2(false);
        }
    }

    const prefixSelector = (
        <Form.Item name="vehicleRegistrationNumberPrefix" noStyle>
            <Select>
                <Select.Option value="bh">BH</Select.Option>
                <Select.Option value="dhk">DHK METRO</Select.Option>
                <Select.Option value="ctg">CTG METRO</Select.Option>
                <Select.Option value="syl">SYL METRO</Select.Option>
                <Select.Option value="khl">KHL METRO</Select.Option>
                <Select.Option value="bn">BN</Select.Option>
                <Select.Option value="br">BR</Select.Option>
                <Select.Option value="ba">BA</Select.Option>
            </Select>
        </Form.Item>
    );

    return (
        <>
            {step === 1 && (
                <Form
                    layout="vertical"
                    onFinish={ProceedNextWithData}
                    style={{ marginTop: '1rem' }}
                    initialValues={{ vehicleRegistrationNumberPrefix: 'bh' }}
                    scrollToFirstError
                >
                    <Row>
                        <span className="form_headline" style={{ width: '100%', textAlign: 'center' }}>Personal Information </span>

                        <Col span={24}>
                            <div style={{ margin: "0 0 2rem 0" }}>
                                <label style={{ fontWeight: 600 }} htmlFor="Dp">
                                    Profile Picture
                                </label>
                                <div className="file-upload">
                                    <div className="image-upload-wrap">
                                        <input className="file-upload-input" type="file" multiple={false} onChange={dpUploadHandler} />
                                        <div className="drag-text">
                                            {xhr === "image" ? <div className='upload_progress_wrapper'><Progress percent={uploadPercentage} /></div> : <h3>Upload Picture</h3>}{" "}
                                        </div>
                                    </div>
                                    {images.length > 0 ? (
                                        <div style={{ marginTop: "2rem" }} className="h-center">
                                            {images.map((image, i) => (
                                                <img className="uploaded_image" key={i} src={image} alt="dp" />
                                            ))}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </Col>

                        <Col span={24}>
                            <Form.Item name='name'
                                label="Full Name (According to National ID Number)"
                                rules={[{ required: true, message: 'আপনার পুরো নাম দিন' }]}
                            >
                                <Input placeholder="Shamim Ferdous" type="text" size="large" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item name='nid'
                                label="National ID Number"
                                rules={[{ required: true, message: 'আপনার জাতীয় পরিচয় পত্র নম্বর দিন' }]}
                            >
                                <Input placeholder="8701550140" type="number" size="large" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item name='address'
                                label="Detailed Address"
                                rules={[{ required: true, message: 'আপনার বিস্তারিত ঠিকানা দিন' }]}
                            >
                                <Input.TextArea placeholder="House No 126/69, Road No 2, Adabor, Mohammadpur, Dhaka" type="text" size="large" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name='gender'
                                label="Gender"
                                rules={[{ required: true, message: 'লিঙ্গ নির্বাচন করুন' }]
                                }
                            >
                                <Select
                                    placeholder="Male"
                                    size="large"
                                >
                                    <Select.Option value="male"> Male </Select.Option>
                                    <Select.Option value="female"> Female </Select.Option>
                                    <Select.Option value="other"> Other </Select.Option>
                                    <Select.Option value="other"> Prefer Not to Mention </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name='dateOfBirth'
                                label="Date of Birth"
                                rules={[{ required: true, message: 'আপনার জন্ম তারিখ নির্বাচন করুন' }]
                                }
                            >
                                <DatePicker
                                    size="large"
                                    allowClear={false}
                                    style={{ width: "100%" }}
                                    placeholder="1998-03-01"
                                />
                            </Form.Item>
                        </Col>

                        <span className="form_headline"> Vehicle Information </span>

                        <Col span={24}>
                            <Form.Item
                                name='make'
                                label="Manufacturer"
                                rules={[{ required: true, message: 'ম্যানুফ্যাকচারার নির্বাচন করুন' }]
                                }
                            >
                                <Select
                                    placeholder="Mazda"
                                    size="large"
                                    onChange={(value) => {
                                        setSelectedMakeOption(value);
                                        getYearOptions(value);
                                    }}
                                >
                                    {makeOptions.map((make, i) => (
                                        <Select.Option value={make} key={i}> {make} </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name='year'
                                label="Manufacturing Year"
                                rules={[{ required: true, message: 'ম্যানুফ্যাকচারিং ইয়ার নির্বাচন করুন' }]
                                }
                            >
                                <Select
                                    placeholder="2004"
                                    size="large"
                                    onChange={(value) => {
                                        getModelOptions(selectedMakeOption, value)
                                    }}
                                >
                                    {yearOptions.map((year, i) => (
                                        <Select.Option value={year} key={i}> {year} </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>


                        <Col span={24}>
                            <Form.Item
                                name='vehicleModel'
                                label="Vehicle Model"
                                rules={[{ required: true, message: 'আপনার গাড়ির মডেল নির্বাচন করুন' }]
                                }
                            >
                                <Select
                                    placeholder="Accord"
                                    size="large"
                                >
                                    {modelOptions.map((model, i) => (
                                        <Select.Option value={model._id} key={i}> {model.model} </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>


                        <Col span={24}>
                            <Form.Item
                                name="vehicleRegistrationNumber"
                                label="Vehicle Registration Number"
                                rules={[
                                    {
                                        required: true,
                                        message: 'আপনার গাড়ির রেজিস্ট্রেশন নম্বর দিন',
                                    },
                                ]}
                            >
                                <Input
                                    addonBefore={prefixSelector}
                                    style={{
                                        width: '100%',
                                    }}
                                    size="large"
                                    placeholder="14-5476"
                                />
                            </Form.Item>
                        </Col>



                        <span className="form_headline"> Driver's Information </span>

                        <Col span={24}>
                            <Form.Item
                                name='drivingLicenseNumber'
                                label="Driving License Number"
                                rules={[{ required: true, message: 'আপনার ড্রাইভিং লাইসেন্স নম্বর দিন' }]}
                            >
                                <Input placeholder="GP1139725Q00115" type="text" size="large" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name='drivingLicenseExpiryDate'
                                label="Driving License Expiry Date"
                                rules={[{ required: true, message: 'আপনার ড্রাইভিং লাইসেন্স এর মেয়াদোত্তীর্ণের তারিখ নির্বাচন করুন' }]
                                }
                            >
                                <DatePicker
                                    size="large"
                                    allowClear={false}
                                    style={{ width: "100%" }}
                                    placeholder="2025-03-01"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item valuePropName="driverUnderPartner">
                                <Checkbox onChange={(e) => {
                                    setShowDriverUnderPartnerForm(e.target.checked);
                                }}>
                                    I am not the owner of vehicle
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        {showDriverUnderPartnerForm && (
                            <>
                                <Col span={24}>
                                    <Form.Item name='ownerName'
                                        label="Owner's Name (According the NID)"
                                        rules={[{ required: true, message: 'আপনার গাড়ির মালিকের নাম দিন' }]}
                                    >
                                        <Input placeholder="Shamim Ferdous" type="text" size="large" />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item name='ownerNumber'
                                        label="Owner's Mobile Number"
                                        rules={[{ required: true, message: 'আপনার গাড়ির মালিকের মোবাইল নম্বর দিন' }]}
                                    >
                                        <Input placeholder="01711111111" type="number" size="large" />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item name='ownerNid'
                                        label="Owner's National ID Number"
                                        rules={[{ required: true, message: 'আপনার গাড়ির মালিকের জাতীয় পরিচয় পত্র নম্বর দিন' }]}
                                    >
                                        <Input placeholder="8701550140" type="number" size="large" />
                                    </Form.Item>
                                </Col>
                            </>

                        )}

                        <Col span={24}>
                            <Form.Item>
                                <Button block size={"large"} htmlType='submit' type="primary" className="primary-button" style={{ marginTop: "2rem" }}>
                                    Next
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            )}

            {step === 2 && (
                <>
                    <Form
                        layout="vertical"
                        onFinish={ProceedNextWithData2}
                        style={{ marginTop: '2rem' }}
                    >
                        <Row>
                            <span className="form_headline"> Vehicle Papers </span>

                            <Col span={24}>
                                <div style={{ margin: "0 0 2rem 0" }}>
                                    <label style={{ fontWeight: 600 }} htmlFor="blue_book_image">
                                        Photo of Blue Book
                                    </label>
                                    <div className="file-upload">
                                        <div className="image-upload-wrap">
                                            <input className="file-upload-input" type="file" multiple={false} onChange={blueBookUploadHandler} />
                                            <div className="drag-text">
                                                {xhr === "image2" ? <div className='upload_progress_wrapper'><Progress percent={uploadPercentage2} /></div> : <h3>Upload Picture</h3>}{" "}
                                            </div>
                                        </div>
                                        {images2.length > 0 ? (
                                            <div style={{ marginTop: "2rem" }} className="h-center">
                                                {images2.map((image, i) => (
                                                    <img className="uploaded_image" key={i} src={image} alt="dp" />
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </Col>

                            <Col span={24}>
                                <div style={{ margin: "0 0 2rem 0" }}>
                                    <label style={{ fontWeight: 600 }} htmlFor="fitness_token_image">
                                        Photo of Fitness Paper
                                    </label>
                                    <div className="file-upload">
                                        <div className="image-upload-wrap">
                                            <input className="file-upload-input" type="file" multiple={false} onChange={fitnessPaperUploadHandler} />
                                            <div className="drag-text">
                                                {xhr === "image3" ? <div className='upload_progress_wrapper'><Progress percent={uploadPercentage3} /></div> : <h3>Upload Picture</h3>}{" "}
                                            </div>
                                        </div>
                                        {images3.length > 0 ? (
                                            <div style={{ marginTop: "2rem" }} className="h-center">
                                                {images3.map((image, i) => (
                                                    <img className="uploaded_image" key={i} src={image} alt="dp" />
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </Col>

                            <Col span={24}>
                                <div style={{ margin: "0 0 2rem 0" }}>
                                    <label style={{ fontWeight: 600 }} htmlFor="tax_token_image">
                                        Photo of Tax Token
                                    </label>
                                    <div className="file-upload">
                                        <div className="image-upload-wrap">
                                            <input className="file-upload-input" type="file" multiple={false} onChange={taxTokenUploadHandler} />
                                            <div className="drag-text">
                                                {xhr === "image4" ? <div className='upload_progress_wrapper'><Progress percent={uploadPercentage4} /></div> : <h3>Upload Picture</h3>}{" "}
                                            </div>
                                        </div>
                                        {images4.length > 0 ? (
                                            <div style={{ marginTop: "2rem" }} className="h-center">
                                                {images4.map((image, i) => (
                                                    <img className="uploaded_image" key={i} src={image} alt="dp" />
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </Col>

                            <span>
                                {" "}
                                By clicking next you are agreeing with our <a href="/">Terms of Service</a>
                                {" "}
                            </span>

                            <Col span={24}>
                                <Form.Item>
                                    <Button loading={loading1} block size={"large"} htmlType='submit' type="primary" className="primary-button" style={{ marginTop: "2rem" }}>
                                        Next
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </>
            )}

            {step === 3 && (
                <>
                    <h4 style={{ marginTop: "2rem" }}>
                        We have sent an OTP to {props.cellNumber} {" "}
                    </h4>
                    <Form
                        layout="vertical"
                        onFinish={handleCarRegistration}
                        style={{ marginTop: "2rem" }}
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name="userGivenOtp"
                                    label="OTP"
                                    rules={[
                                        {
                                            required: true,
                                            message: "আপনার ওটিপি দিন",
                                        },
                                    ]}
                                >
                                    <Input style={{ marginBottom: '6rem' }} type="number" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <Button
                                        loading={loading2}
                                        block
                                        size={"large"}
                                        htmlType="submit"
                                        type="primary"
                                        className="primary-button"
                                    >
                                        Register
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </>
            )}

            {step === 4 && (
                <>
                    <div
                        style={{
                            textAlign: "center",
                            padding: "2.5rem 0",
                        }}
                    >
                        <FaRegCheckCircle size={45} color="var(--color-green)" />
                        <h1 style={{ fontWeight: "bold" }}> Congratulations! </h1>
                        <h2 style={{ fontWeight: "400", fontSize: '1.6rem' }}>
                            {" "}
                            Your registration with Transporter was successful. We'll verify your information and get back to you in 48 hours.{" "}
                        </h2>
                        <Link to="/">
                            <Button style={{ marginTop: '2rem' }} type="primary" size="large">
                                Back to Home
                            </Button>
                        </Link>
                    </div>
                </>
            )}
        </>

    );
}

export default CarRegistrationForm;