// // import React, { useState } from 'react';
// // import { Document, Page, pdfjs } from 'react-pdf';
// //
// // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// // const ViewPDF =({url}) =>{
// //   const [numPages, setNumPages] = useState(null);
// //   const [pageNumber, setPageNumber] = useState(1);
// //
// //   function onDocumentLoadSuccess({ numPages }) {
// //     setNumPages(numPages);
// //   }
// //
// //   return (
// //     <div style={{width: '100vw', height: '100vh'}}>
// //       <Document page={2} file={'ride-share-2017.pdf'} onLoadSuccess={onDocumentLoadSuccess} style={{width: '100vw'}}>
// //         <Page pageNumber={2} height={document.getElementsByClassName('PdfDiv')[0]?.clientHeight*0.8 ?? 150} />
// //       </Document>
// //       <p>
// //         Page {pageNumber} of {numPages}
// //       </p>
// //     </div>
// //   );
// // }
// //
// // export default ViewPDF;
//
//
// // Import the main component
// import { SpecialZoomLevel, Viewer } from "@react-pdf-viewer/core"; // install this library
// // Plugins
// import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"; // install this library
//
// // Import the styles
// import "@react-pdf-viewer/core/lib/styles/index.css";
// import "@react-pdf-viewer/default-layout/lib/styles/index.css";
//
// // Worker
// import { Worker } from "@react-pdf-viewer/core"; // install this library
//
//
// const ViewPdf = ({ url }) => {
//     const defaultLayoutPluginInstance = defaultLayoutPlugin();
//     return (
//         <>
//             {url && (
//                 <>
//                     <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
//                         <div>
//                             <Viewer
//                                 fileUrl={`https://api.terragoug.com/api/public/commons/files/pdf?pdf_url=${url}`}
//                                 defaultScale={SpecialZoomLevel.ActualSize}
//                                 plugins={[defaultLayoutPluginInstance]}
//                             />
//                         </div>
//                     </Worker>
//                 </>
//             )}
//         </>
//     );
// };
//
// export default ViewPdf;


import styles from './ViewPDF.module.scss';
import React from 'react';

const ViewPdf = ({url}) => {
    return (
        <div style={{display: 'flex', alignItems: 'center', justifyItems: 'center', width: '100vw', height: '100vh', backgroundColor: 'white'}}>
            <object className={styles.wrapper} data={url} type="application/pdf">
                <iframe src={`https://docs.google.com/viewer?url=${url}&embedded=true`}></iframe>
            </object>
        </div>
    );
};

export default ViewPdf;