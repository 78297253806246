import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// importing components
import { Row, Col, Form, Input, Button, DatePicker, Select, Progress, message, Checkbox } from 'antd';

// importing axios
import axios from '../../api/axios';

// importing icons
import { FaRegCheckCircle } from 'react-icons/fa';

const BikeRegistrationForm = (props) => {
    const [step, setStep] = useState(1);
    const [xhr, setXhr] = useState(null);
    const [step1Values, setStep1Values] = useState({});
    const [step2Values, setStep2Values] = useState({});
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const [images, setImages] = useState([]);
    const [uploadPercentage, setUploadPercentage] = useState(0);

    const [images2, setImages2] = useState([]);
    const [uploadPercentage2, setUploadPercentage2] = useState(0);

    const [images3, setImages3] = useState([]);
    const [uploadPercentage3, setUploadPercentage3] = useState(0);

    const [images4, setImages4] = useState([]);
    const [uploadPercentage4, setUploadPercentage4] = useState(0);

    const [makeOptions, setMakeOptions] = useState([]);
    const [modelOptions, setModelOptions] = useState([]);

    const [showDriverUnderPartnerForm, setShowDriverUnderPartnerForm] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);

    useEffect(() => {
        axios.get('/vehicles/v1/filter-options').then((res) => {
            setMakeOptions(res.data.makeOptions);
        });
    }, []);

    const getModelOptions = (makeOption) => {
        axios.get('/vehicles/v1', { params: { make: makeOption, type: 'bike' } }).then((res) => {
            setModelOptions(res.data);
        });
    };

    const dpUploadHandler = async (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr('image');

            let formData = new FormData();

            formData.append('image', e.target.files[i]);

            const res = await axios.post('/misc/v1/image-upload', formData, {
                withCredentials: true,
                onUploadProgress: (progressEvent) => {
                    const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setUploadPercentage(percentage);
                },
            });
            setImages([res.data.secureUrl]);
            setXhr(null);
        }
    };

    const blueBookUploadHandler = async (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr('image2');

            let formData = new FormData();

            formData.append('image', e.target.files[i]);

            const res = await axios.post('/misc/v1/image-upload', formData, {
                withCredentials: true,
                onUploadProgress: (progressEvent) => {
                    const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setUploadPercentage2(percentage);
                },
            });
            setImages2([res.data.secureUrl]);
            setXhr(null);
        }
    };

    // const insurancePaperUploadHandler = async (e) => {
    //     for (let i = 0; i < e.target.files.length; i++) {
    //         setXhr("image3");

    //         let formData = new FormData();

    //         formData.append("image", e.target.files[i]);

    //         const res = await axios.post('/misc/v1/image-upload', formData, {
    //             withCredentials: true,
    //             onUploadProgress: (progressEvent) => {
    //                 const percentage = Math.round((progressEvent.loaded / progressEvent.total * 100))
    //                 setUploadPercentage3(percentage);
    //             }
    //         })
    //         setImages3([res.data.secureUrl]);
    //         setXhr(null);
    //     }
    // };

    const taxTokenUploadHandler = async (e) => {
        for (let i = 0; i < e.target.files.length; i++) {
            setXhr('image4');

            let formData = new FormData();

            formData.append('image', e.target.files[i]);

            const res = await axios.post('/misc/v1/image-upload', formData, {
                withCredentials: true,
                onUploadProgress: (progressEvent) => {
                    const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setUploadPercentage4(percentage);
                },
            });
            setImages4([res.data.secureUrl]);
            setXhr(null);
        }
    };

    const ProceedNextWithData = (values) => {
        if (images.length < 1) {
            message.error('কমপক্ষে একটি ছবি আপলোড করুন');
            return;
        }
        if (images.length > 1) {
            message.error('মাত্র একটি ছবি আপলোড করুন');
            return;
        }
        values.dp = images[0];
        values.dateOfBirth = values.dateOfBirth !== '' ? moment(values.dateOfBirth).format() : null;
        values.drivingLicenseExpiryDate = values.drivingLicenseExpiryDate !== '' ? moment(values.drivingLicenseExpiryDate).format() : null;
        values.vehicleRegistrationNumber = `${values.vehicleRegistrationNumberPrefix}-${values.vehicleRegistrationNumber}`;
        values.driverUnderPartner = showDriverUnderPartnerForm;
        setStep1Values(values);
        setStep(2);
    };

    const ProceedNextWithData2 = async (values) => {
        if (images2.length < 1 || images2.length > 1) {
            message.error('ব্লু বুক এর একটি ছবি আপলোড করুন');
            return;
        }

        // if (images3.length < 1 || images3.length > 1) {
        //     message.error("ইন্সুরেন্স পেপার এর একটি ছবি আপলোড করুন")
        //     return;
        // }

        if (images4.length < 1 || images4.length > 1) {
            message.error('ট্যাক্স টোকেন এর একটি ছবি আপলোড করুন');
            return;
        }

        values.cellNumber = props.cellNumber;
        values.city = props.city;
        values.vehicleType = props.vehicleType;
        values.blueBook = images2[0];
        // values.insurancePaper = images3[0];
        values.taxToken = images4[0];

        try {
            setLoading1(true);

            const res = await axios.post('/misc/v1/send-otp', {
                cellNumber: props.cellNumber,
            });

            values.hashedOtp = res.data.hashedOtp;
            setStep2Values(values);
            setStep(3);
        } catch (error) {
            console.log(error);
            message.error('Something went wrong!');
        } finally {
            setLoading1(false);
        }
    };

    const handleBikeRegistration = async (values) => {
        try {
            setLoading2(true);
            let payload = {
                ...step1Values,
                ...step2Values,
                ...values,
            };
            // console.log(payload);

            // first send request to user registration endpoint
            let response;
            try {
                response = await axios.post('/users/v1/register', {
                    cellNumber: payload.cellNumber,
                    name: payload.name,
                    hashedOtp: payload.hashedOtp,
                    userGivenOtp: payload.userGivenOtp,
                });
            } catch (error) {
                console.log(error);
                message.error(error.response.data.msg);
                return;
            }

            delete payload.hashedOtp;
            delete payload.userGivenOtp;

            // next send rider registration endpoint
            await axios.post(
                '/users/v1/rider/details-submission',
                {
                    user: response.data.user._id,
                    ...payload,
                },
                {
                    headers: {
                        accesstoken: response.data.token,
                    },
                }
            );
            setStep(4);
        } catch (error) {
            console.log(error);
            message.error('Something went wrong!');
        } finally {
            setLoading2(false);
        }
    };

    const prefixSelector = (
        <Form.Item name='vehicleRegistrationNumberPrefix' noStyle>
            <Select>
                <Select.Option value='bh'>BH</Select.Option>
                <Select.Option value='dhk'>DHK METRO</Select.Option>
                <Select.Option value='ctg'>CTG METRO</Select.Option>
                <Select.Option value='syl'>SYL METRO</Select.Option>
                <Select.Option value='khl'>KHL METRO</Select.Option>
                <Select.Option value='bn'>BN</Select.Option>
                <Select.Option value='br'>BR</Select.Option>
                <Select.Option value='ba'>BA</Select.Option>
            </Select>
        </Form.Item>
    );

    return (
        <>
            {step === 1 && (
                <Form
                    layout='vertical'
                    onFinish={ProceedNextWithData}
                    style={{ marginTop: '2rem' }}
                    initialValues={{ vehicleRegistrationNumberPrefix: 'bh' }}
                    scrollToFirstError
                >
                    <Row>
                        <span className='form_headline'> ব্যক্তিগত তথ্য </span>

                        <Col span={24}>
                            <div style={{ margin: '0 0 2rem 0' }}>
                                <label style={{ fontWeight: 600 }} htmlFor='Dp'>
                                    প্রোফাইল পিকচার
                                </label>
                                <div className='file-upload'>
                                    <div className='image-upload-wrap'>
                                        <input className='file-upload-input' type='file' multiple={false} onChange={dpUploadHandler} />
                                        <div className='drag-text'>
                                            {xhr === 'image' ? (
                                                <div className='upload_progress_wrapper'>
                                                    <Progress percent={uploadPercentage} />
                                                </div>
                                            ) : (
                                                <h3>ছবি আপলোড করুন</h3>
                                            )}{' '}
                                        </div>
                                    </div>
                                    {images.length > 0 ? (
                                        <div style={{ marginTop: '2rem' }} className='h-center'>
                                            {images.map((image, i) => (
                                                <img className='uploaded_image' key={i} src={image} alt='dp' />
                                            ))}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name='name'
                                label='পুরো নাম (জাতীয় পরিচয় পত্র অনুসারে)'
                                rules={[{ required: true, message: 'আপনার পুরো নাম দিন' }]}
                            >
                                <Input placeholder='Shamim Ferdous' type='text' size='large' />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name='nid'
                                label='জাতীয় পরিচয় পত্র নম্বর'
                                rules={[{ required: true, message: 'আপনার জাতীয় পরিচয় পত্র নম্বর দিন' }]}
                            >
                                <Input placeholder='8701550140' type='number' size='large' />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item name='address' label='বিস্তারিত ঠিকানা' rules={[{ required: true, message: 'আপনার বিস্তারিত ঠিকানা দিন' }]}>
                                <Input.TextArea placeholder='House No 126/69, Road No 2, Adabor, Mohammadpur, Dhaka' type='text' size='large' />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item name='gender' label='লিঙ্গ' rules={[{ required: true, message: 'লিঙ্গ নির্বাচন করুন' }]}>
                                <Select placeholder='পুরুষ' size='large'>
                                    <Select.Option value='male'> পুরুষ </Select.Option>
                                    <Select.Option value='female'> মহিলা </Select.Option>
                                    <Select.Option value='other'> অন্যান্য </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item name='dateOfBirth' label='জন্ম তারিখ' rules={[{ required: true, message: 'আপনার জন্ম তারিখ নির্বাচন করুন' }]}>
                                <DatePicker size='large' allowClear={false} style={{ width: '100%' }} placeholder='1998-03-01' />
                            </Form.Item>
                        </Col>

                        <span className='form_headline'>বাইকের তথ্য</span>

                        <Col span={24}>
                            <Form.Item name='make' label='ম্যানুফ্যাকচারার' rules={[{ required: true, message: 'ম্যানুফ্যাকচারার নির্বাচন করুন' }]}>
                                <Select
                                    placeholder='Mazda'
                                    size='large'
                                    onChange={(value) => {
                                        getModelOptions(value);
                                    }}
                                >
                                    {makeOptions.map((make, i) => (
                                        <Select.Option value={make} key={i}>
                                            {' '}
                                            {make}{' '}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name='vehicleModel'
                                label='বাইকের মডেল'
                                rules={[{ required: true, message: 'আপনার বাইকের মডেল নির্বাচন করুন' }]}
                            >
                                <Select placeholder='Accord' size='large'>
                                    {modelOptions.map((model, i) => (
                                        <Select.Option value={model._id} key={i}>
                                            {' '}
                                            {model.model}{' '}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name='vehicleRegistrationNumber'
                                label='বাইকের রেজিস্ট্রেশন নম্বর'
                                rules={[
                                    {
                                        required: true,
                                        message: 'আপনার বাইকের রেজিস্ট্রেশন নম্বর দিন',
                                    },
                                ]}
                            >
                                <Input
                                    // addonBefore={prefixSelector}
                                    style={{
                                        width: '100%',
                                    }}
                                    type='text'
                                    size='large'
                                    placeholder='14-5476'
                                />
                            </Form.Item>
                        </Col>

                        <span className='form_headline'> চালকের তথ্য </span>

                        <Col span={24}>
                            <Form.Item
                                name='drivingLicenseNumber'
                                label='ড্রাইভিং লাইসেন্স নম্বর'
                                rules={[{ required: true, message: 'আপনার ড্রাইভিং লাইসেন্স নম্বর দিন' }]}
                            >
                                <Input placeholder='GP1139725Q00115' type='text' size='large' />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name='drivingLicenseExpiryDate'
                                label='ড্রাইভিং লাইসেন্স এর মেয়াদোত্তীর্ণের তারিখ'
                                rules={[{ required: true, message: 'আপনার ড্রাইভিং লাইসেন্স এর মেয়াদোত্তীর্ণের তারিখ নির্বাচন করুন' }]}
                            >
                                <DatePicker size='large' allowClear={false} style={{ width: '100%' }} placeholder='2025-03-01' />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item valuePropName='driverUnderPartner'>
                                <Checkbox
                                    onChange={(e) => {
                                        setShowDriverUnderPartnerForm(e.target.checked);
                                    }}
                                >
                                    আমি বাইকের মালিক নই
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        {showDriverUnderPartnerForm && (
                            <>
                                <Col span={24}>
                                    <Form.Item
                                        name='ownerName'
                                        label='মালিকের নাম (জাতীয় পরিচয় পত্র অনুসারে)'
                                        rules={[{ required: true, message: 'আপনার বাইকের মালিকের নাম দিন' }]}
                                    >
                                        <Input placeholder='Shamim Ferdous' type='text' size='large' />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name='ownerNumber'
                                        label='মালিকের মোবাইল নম্বর'
                                        rules={[{ required: true, message: 'আপনার বাইকের মালিকের মোবাইল নম্বর দিন' }]}
                                    >
                                        <Input placeholder='01711111111' type='number' size='large' />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        name='ownerNid'
                                        label='মালিকের জাতীয় পরিচয় পত্র নম্বর'
                                        rules={[{ required: true, message: 'আপনার বাইকের মালিকের জাতীয় পরিচয় পত্র নম্বর দিন' }]}
                                    >
                                        <Input placeholder='8701550140' type='number' size='large' />
                                    </Form.Item>
                                </Col>
                            </>
                        )}

                        <Col span={24}>
                            <Form.Item>
                                <Button
                                    block
                                    size={'large'}
                                    htmlType='submit'
                                    type='primary'
                                    className='primary-button'
                                    style={{ marginTop: '2rem' }}
                                >
                                    পরবর্তী
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            )}

            {step === 2 && (
                <>
                    <Form layout='vertical' onFinish={ProceedNextWithData2} style={{ marginTop: '2rem' }}>
                        <Row>
                            <span className='form_headline'> বাইকের কাগজপত্র </span>

                            <Col span={24}>
                                <div style={{ margin: '0 0 2rem 0' }}>
                                    <label style={{ fontWeight: 600 }} htmlFor='blue_book_image'>
                                        ব্লু বুক এর ছবি
                                    </label>
                                    <div className='file-upload'>
                                        <div className='image-upload-wrap'>
                                            <input className='file-upload-input' type='file' multiple={false} onChange={blueBookUploadHandler} />
                                            <div className='drag-text'>
                                                {xhr === 'image2' ? (
                                                    <div className='upload_progress_wrapper'>
                                                        <Progress percent={uploadPercentage2} />
                                                    </div>
                                                ) : (
                                                    <h3>ছবি আপলোড করুন</h3>
                                                )}{' '}
                                            </div>
                                        </div>
                                        {images2.length > 0 ? (
                                            <div style={{ marginTop: '2rem' }} className='h-center'>
                                                {images2.map((image, i) => (
                                                    <img className='uploaded_image' key={i} src={image} alt='dp' />
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </Col>

                            {/* <Col span={24}>
                                <div style={{ margin: "0 0 2rem 0" }}>
                                    <label style={{ fontWeight: 600 }} htmlFor="insurance_paper_image">
                                        ইন্সুরেন্স পেপার এর ছবি
                                    </label>
                                    <div className="file-upload">
                                        <div className="image-upload-wrap">
                                            <input className="file-upload-input" type="file" multiple={false} onChange={insurancePaperUploadHandler} />
                                            <div className="drag-text">
                                                {xhr === "image3" ? <div className='upload_progress_wrapper'><Progress percent={uploadPercentage3} /></div> : <h3>ছবি আপলোড করুন</h3>}{" "}
                                            </div>
                                        </div>
                                        {images3.length > 0 ? (
                                            <div style={{ marginTop: "2rem" }} className="h-center">
                                                {images3.map((image, i) => (
                                                    <img className="uploaded_image" key={i} src={image} alt="dp" />
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </Col> */}

                            <Col span={24}>
                                <div style={{ margin: '0 0 2rem 0' }}>
                                    <label style={{ fontWeight: 600 }} htmlFor='tax_token_image'>
                                        ট্যাক্স টোকেন এর ছবি
                                    </label>
                                    <div className='file-upload'>
                                        <div className='image-upload-wrap'>
                                            <input className='file-upload-input' type='file' multiple={false} onChange={taxTokenUploadHandler} />
                                            <div className='drag-text'>
                                                {xhr === 'image4' ? (
                                                    <div className='upload_progress_wrapper'>
                                                        <Progress percent={uploadPercentage4} />
                                                    </div>
                                                ) : (
                                                    <h3>ছবি আপলোড করুন</h3>
                                                )}{' '}
                                            </div>
                                        </div>
                                        {images4.length > 0 ? (
                                            <div style={{ marginTop: '2rem' }} className='h-center'>
                                                {images4.map((image, i) => (
                                                    <img className='uploaded_image' key={i} src={image} alt='dp' />
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </Col>

                            <span>
                                {' '}
                                "পরবর্তী" ক্লিক করে আপনি আমাদের <a href='/'>শর্তাবলী</a>র সাথে সম্মত হচ্ছেন।{' '}
                            </span>

                            <Col span={24}>
                                <Form.Item>
                                    <Button
                                        block
                                        loading={loading1}
                                        size={'large'}
                                        htmlType='submit'
                                        type='primary'
                                        className='primary-button'
                                        style={{ marginTop: '2rem' }}
                                    >
                                        পরবর্তী
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </>
            )}

            {step === 3 && (
                <>
                    <h4 style={{ marginTop: '2rem' }}>আপনার {props.cellNumber} নাম্বার এ একটি 4 সংখ্যার কোড পাঠানো হয়েছে। </h4>
                    <Form layout='vertical' onFinish={handleBikeRegistration} style={{ marginTop: '2rem' }}>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name='userGivenOtp'
                                    label='ওটিপি'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'আপনার ওটিপি দিন',
                                        },
                                    ]}
                                >
                                    <Input type='number' />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <Button loading={loading2} block size={'large'} htmlType='submit' type='primary' className='primary-button'>
                                        রেজিস্ট্রেশন করুন
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </>
            )}

            {step === 4 && (
                <>
                    <div
                        style={{
                            textAlign: 'center',
                            padding: '2.5rem 0',
                        }}
                    >
                        <FaRegCheckCircle size={45} color='var(--color-green)' />
                        <h1 style={{ fontWeight: 'bold' }}> অভিনন্দন! </h1>
                        <h2 style={{ fontWeight: '300' }}>
                            {' '}
                            আপনার রেজিস্ট্রেশন সফল হয়েছে। আপনার তথ্য যাচাই করে অতি শীঘ্রই আমরা আপনার সাথে যোগাযোগ করবো।{' '}
                        </h2>
                        <Link to='/'>
                            <Button type='primary' size='large'>
                                হোমপেজে ফিরে যান
                            </Button>
                        </Link>
                    </div>
                </>
            )}
        </>
    );
};

export default BikeRegistrationForm;
